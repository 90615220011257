import { useTranslation } from 'react-i18next'

import { WhyChooseUs1, WhyChooseUs2 } from 'assets/icons'
import { Language } from 'constants/constants'
import { getAppLang, getTKey } from 'utils/language'

const WhyChooseSection = () => {
	const { t } = useTranslation()

	const tKey = getTKey('home')
	return (
		<section className="bg-[#b790d9] relative py-12 px-4 md:mx-20 rounded-3xl overflow-hidden">
			<img src={WhyChooseUs1} alt="Decorative Circle" className="absolute top-0 left-0 z-0" />
			<img
				src={WhyChooseUs2}
				alt="Decorative Circle"
				className="absolute bottom-0 right-0 z-0 hidden md:block"
				style={{ right: '250px' }}
			/>

			<div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center relative z-10">
				<div className="flex-1 text-center md:text-left mb-8 md:mb-0">
					<h2 className="text-[32px] md:text-[40px] font-bold text-white mb-4 leading-tight">
						{t(tKey('headings.chooseAppointo'))}{' '}
						<span className="block text-[#2c2d6a]">
							Styling Corner{getAppLang() === Language.EN && '?'}
						</span>
						{getAppLang() === Language.DE && t(tKey('headings.choose')) + '?'}
					</h2>
					<p className="text-md md:text-md text-white mb-6 mr-2">
						{t(tKey('labels.discoverAppointo'))}
					</p>
				</div>

				<div className="flex-1 grid grid-cols-1 gap-6 md:grid-cols-2">
					<div className="bg-white p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">
							{t(tKey('chooseAppointo.headings.flexiblePayment'))}
						</h3>
						<p
							className="text-sm md:text-md text-gray-600 mt-2 [&>span]:text-[#e71d73]"
							dangerouslySetInnerHTML={{ __html: t(tKey('chooseAppointo.labels.flexiblePayment')) }}
						/>
					</div>

					<div className="bg-white p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">
							{t(tKey('chooseAppointo.headings.customerSupport'))}
						</h3>
						<p
							className="text-sm md:text-md text-gray-600 mt-2 [&>span]:text-[#e71d73]"
							dangerouslySetInnerHTML={{ __html: t(tKey('chooseAppointo.labels.customerSupport')) }}
						/>
					</div>

					<div className="bg-white p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">
							{t(tKey('chooseAppointo.headings.onlineBooking'))}
						</h3>
						<p
							className="text-sm md:text-md text-gray-600 mt-2 [&>span]:text-[#e71d73]"
							dangerouslySetInnerHTML={{ __html: t(tKey('chooseAppointo.labels.onlineBooking')) }}
						/>
					</div>

					<div className="bg-white p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">
							{t(tKey('chooseAppointo.headings.secureAndReliable'))}
						</h3>
						<p
							className="text-sm md:text-md text-gray-600 mt-2 [&>span]:text-[#e71d73]"
							dangerouslySetInnerHTML={{
								__html: t(tKey('chooseAppointo.labels.secureAndReliable'))
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default WhyChooseSection
