import { Language } from 'constants/constants'
import { getAppLang } from 'utils/language'

export const calculateServicePrice = (price: string, healthInsurance: boolean) => {
	const appLanguage = getAppLang()
	if (!healthInsurance && price === '0')
		return appLanguage === Language.EN ? 'Available for free' : 'Kostenlos erhältlich'
	if (price && healthInsurance)
		return appLanguage === Language.EN
			? 'Covered by Health Insurance'
			: 'Von der Krankenkasse abgedeckt'
	return `CHF ${Number(price).toFixed(2)}`
}

export const adjustPrice = (price: number): number => Math.max(price, 0)

export const getServiceDisplayPrice = (appointmentData: Appointment) => {
	const service = appointmentData.service
	if (!service) return null

	const basePrice = appointmentData.is_discounted ? service.discountedPrice : service.price

	const finalPrice = adjustPrice(
		Number(basePrice) -
			(appointmentData.used_coupon ? appointmentData.used_coupon.discount_amount : 0)
	).toFixed(2)

	return calculateServicePrice(finalPrice, service.health_insurance === '1')
}
