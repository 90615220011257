import { useEffect, useState } from 'react'

const useIsLargeScreen = () => {
	const [isLargeScreen, setIsLargeScreen] = useState<boolean>()

	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth >= 1535)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return isLargeScreen
}

export default useIsLargeScreen
