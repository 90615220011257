import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { PinInput } from 'components/inputs/pin-input'
import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

interface OtpProps {
	pin: string
	onChange: (value: string) => void
	onResendOtp: () => void
	phoneNumber: string
	isLoading: boolean
	showSubmitButton?: boolean
	showHighlights?: boolean
}

export const Otp = ({
	pin,
	onChange,
	onResendOtp,
	isLoading,
	phoneNumber,
	showHighlights = true,
	showSubmitButton = true
}: OtpProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('auth')
	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('otp.labels.otpVerification'))}
			</h2>
			<p className="text-secondary font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{t(tKey('otp.labels.enterTheOTPWeJustSentToYourNumber'))}
				<span className="italic">{phoneNumber}</span>
			</p>
			<div className="max-lg:px-5">
				<PinInput value={pin} valueLength={6} onChange={onChange} />
				<div className="flex items-center justify-center gap-x-4 text-sm pt-12 pb-6">
					<div className="text-sm text-secondary flex items-center ">
						{t(tKey('otp.labels.dontReceiveTheOTP'))}
						<span onClick={onResendOtp} className="cursor-pointer underline text-[#5964D3] ml-1">
							{t(tKey('otp.labels.resendOTP'))}
						</span>
					</div>
				</div>
				{showSubmitButton && (
					<div className="flex justify-center my-6">
						<Button
							className="bg-primary-light text-white w-3/5 py-3 px-0 capitalize text-sm"
							type="submit"
							disabled={isLoading}>
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('otp.labels.pleaseWait'))}
									</span>
								</div>
							) : (
								<span> {t(tKey('otp.labels.send'))}</span>
							)}
						</Button>
					</div>
				)}
			</div>
			{showHighlights && <Highlights />}
		</div>
	)
}
