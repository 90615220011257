import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

import { ChevronDownIcon } from 'assets/icons'
import clsx from 'clsx'
import { Language } from 'constants/constants'
import useIsMobile from 'hooks/useIsMobile'

interface ListBoxProps {
	options: string[]
	value: string
	onChange: (value: string) => void
	icon?: string
	className?: string
}
export const LanguageMenu = ({ value, options, icon, onChange, className }: ListBoxProps) => {
	const isMobile = useIsMobile()

	const handleChange = (value: string) => {
		onChange(value)
	}

	return (
		<div className="w-20">
			<Listbox value={value} onChange={handleChange}>
				<div className="relative">
					<Listbox.Button
						className={clsx(
							`relative flex items-center gap-x-1 w-full cursor-pointer rounded-[100px] bg-[#FFFFFF4D] text-white border border-[#D3E3F1] search-shadow px-3 py-2.5 text-center focus:outline-none focus-visible:border-main focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-main-300 sm:text-sm`,
							className
						)}>
						<span className="uppercase flex shrink-0 items-center gap-x-2 truncate font-semibold text-md">
							{(value === Language.EN || value === 'en-US') && (
								<span className="fi fi-us rounded-full text-lg" />
							)}
							{value === Language.DE && <span className="fi fi-ch rounded-full text-lg" />}
							<span className="text-xs">{value === 'en-US' ? 'EN' : value}</span>
						</span>
						<span className="pointer-events-none flex items-center">
							<img
								src={icon ? icon : ChevronDownIcon}
								className="absolute h-6 w-6 right-0 translate-x-0.5"
							/>
						</span>
					</Listbox.Button>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{options.map((option, index) => (
								<Listbox.Option
									key={index}
									className={({ active }) =>
										`relative cursor-pointer z-50 select-none py-2 pl-10 font-semibold pr-4 capitalize text-md ${
											active ? 'bg-gray-200 text-main' : 'text-secondary bg-white'
										}`
									}
									value={option}>
									{({ selected }) => (
										<>
											<span className={`block truncate ${selected ? 'font-bold' : 'font-medium'}`}>
												{index === 0 && <span className="fi fi-us rounded-full text-lg" />}
												{index === 1 && <span className="fi fi-ch rounded-full text-lg" />}
											</span>
											{selected ? (
												<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
		</div>
	)
}
