import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	visitReasonForm: {} as VisitReasonForm,
	selectedParameters: {
		service: {} as Service,
		category: {} as CompanyCategory
	}
}

const appointmentSlice = createSlice({
	name: 'appointment',
	initialState,
	reducers: {
		saveSelectedParameters(
			state,
			action: PayloadAction<{ service: Service; category: CompanyCategory }>
		) {
			return { ...state, selectedParameters: { ...action.payload } }
		},
		saveVisitForm(state, action: PayloadAction<{ visitReasonForm: VisitReasonForm }>) {
			return { ...state, visitReasonForm: action.payload.visitReasonForm }
		}
	}
})

export const { saveSelectedParameters, saveVisitForm } = appointmentSlice.actions
export default appointmentSlice.reducer
