import axios from 'services/axios'

const createCoupon = (data: { pointsCost: number; discountAmount: number }) => {
	return axios.post('/coupons', data).then(response => response.data)
}

const verifyCoupon = (data: { couponCode: string; serviceId: string }) => {
	return axios.post('/verify-coupon', data).then(response => response.data)
}

const getCouponById = (id: string): Promise<Coupon> => {
	return axios.get(`/coupons/${id}`).then(response => response.data)
}

const getCoupons = (): Promise<Coupon[]> => {
	return axios.get('/coupons').then(respnse => respnse.data)
}

const couponService = {
	getCouponById,
	getCoupons,
	createCoupon,
	verifyCoupon
}

export default couponService
