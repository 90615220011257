import { Link } from 'react-router-dom'

import { DocumentPreviewIcon } from 'assets/icons'
import { Document, Page } from 'react-pdf'

interface DocumentCardProps {
	name: string
	addedBy: string
	url: string
	appointmentId?: { appointmentNumber: Number; _id: string }
	companyName?: string
}

export const DocumentCard = ({
	name,
	addedBy,
	url,
	appointmentId,
	companyName
}: DocumentCardProps) => {
	const getFileType = (name: string) => {
		const extension = name.split('.').pop()?.toLowerCase()
		if (extension === 'pdf') return 'pdf'
		if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(extension || '')) return 'image'
		return 'doc'
	}

	const fileType = getFileType(name)

	return (
		<div
			style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.12)' }}
			className="p-3 w-full bg-white border border-[#26B6A64D] rounded-lg flex flex-col gap-y-3">
			<div className="flex justify-between items-center">
				<div className="bg-[#F6F8FB] rounded-[1px] p-px">
					{fileType === 'image' ? (
						<img src={url} alt={name} className="h-5 w-5 object-cover" />
					) : fileType === 'pdf' ? (
						<Document
							file={url}
							onLoadError={error => console.error('Error loading PDF:', error)}
							loading={<div>Loading...</div>}
							className="h-5 w-5 overflow-hidden">
							<Page
								pageNumber={1}
								width={30}
								height={30}
								renderAnnotationLayer={false}
								renderTextLayer={false}
							/>
						</Document>
					) : (
						<img src={DocumentPreviewIcon} alt="Document Icon" className="h-5 w-5" />
					)}
					{/* <img src={DocumentPreviewIcon} className="h-5 w-5" /> */}
				</div>
				{appointmentId && (
					<Link
						to={`/user/appointments/details/${appointmentId._id}`}
						className="px-1 py-0.5 bg-[#F6F8FB] text-secondary rounded-full text-xs font-semibold">
						{appointmentId.appointmentNumber.toString().padStart(7, '0')}
					</Link>
				)}
			</div>
			<div className="flex flex-col gap-y-2.5">
				{companyName && (
					<p className="text-[#757575] text-xs font-semibold text-ellipsis overflow-hidden whitespace-nowrap">
						{companyName}
					</p>
				)}
				<h5 className="text-sm font-semibold text-primary">{name}</h5>
				<div className="flex justify-between">
					<div className="flex gap-x-1 items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="15"
							viewBox="0 0 14 15"
							fill="none">
							<path
								d="M7.00033 6.74611C6.51571 6.74611 6.10289 6.5756 5.76187 6.23457C5.42084 5.89354 5.25033 5.48072 5.25033 4.99611C5.25033 4.51149 5.42084 4.09866 5.76187 3.75763C6.10289 3.41661 6.51571 3.24609 7.00033 3.24609C7.48494 3.24609 7.89776 3.41661 8.23879 3.75763C8.57981 4.09866 8.75033 4.51149 8.75033 4.99611C8.75033 5.48072 8.57981 5.89354 8.23879 6.23457C7.89776 6.5756 7.48494 6.74611 7.00033 6.74611ZM2.91699 10.3583V10.0038C2.91699 9.76298 2.9871 9.53769 3.12733 9.32791C3.26755 9.11814 3.4562 8.95529 3.69328 8.83937C4.2437 8.57537 4.7945 8.37738 5.34567 8.24538C5.89685 8.11338 6.44841 8.04738 7.00033 8.04738C7.55225 8.04738 8.1038 8.11338 8.65498 8.24538C9.20615 8.37738 9.75695 8.57537 10.3074 8.83937C10.5445 8.95529 10.7331 9.11814 10.8733 9.32791C11.0135 9.53769 11.0837 9.76298 11.0837 10.0038V10.3583C11.0837 10.5296 11.0255 10.6733 10.9092 10.7896C10.7929 10.9059 10.6492 10.9641 10.4779 10.9641H3.52275C3.3515 10.9641 3.20772 10.9059 3.09142 10.7896C2.97514 10.6733 2.91699 10.5296 2.91699 10.3583Z"
								fill="#4C6780"
							/>
						</svg>
						<p className="text-secondary text-xs">{addedBy}</p>
					</div>
					<Link download={name} to={url} target="__blank">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none">
							<path
								d="M15.75 11.3555V14.3555C15.75 14.7533 15.592 15.1348 15.3107 15.4161C15.0294 15.6974 14.6478 15.8555 14.25 15.8555H3.75C3.35218 15.8555 2.97064 15.6974 2.68934 15.4161C2.40804 15.1348 2.25 14.7533 2.25 14.3555V11.3555"
								stroke="#123258"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M5.25 7.60547L9 11.3555L12.75 7.60547"
								stroke="#123258"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9 11.3555V2.35547"
								stroke="#123258"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Link>
				</div>
			</div>
		</div>
	)
}
