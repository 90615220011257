import { ChevronLeftIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { DocumentPreviewIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { MenuDropdown } from 'components/app/dropdown'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { Table } from 'components/app/table'
import { DocumentCard } from 'components/cards/document-card'
import { UploadModal } from 'components/document/upload-modal'
import { InputDate } from 'components/inputs/date'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { Document, Page } from 'react-pdf'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'

enum DocumentType {
	ALL = 'all',
	GENERAL = 'general',
	APPOINTMENT = 'appointment',
	PERSONAL = 'personal'
}

export const UserDocuments = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('documents')
	const appLanguage = getAppLang()

	const user = useAppSelector(state => state.user)

	const [documents, setDocuments] = useState<UserDocument>()
	const [selectedDate, setSelectedDate] = useState<string>()
	const [documentType, setDocumentType] = useState(DocumentType.ALL)
	const [selectedNote, setSelectedNote] = useState<UserNote>()
	const [searchText, setSearchText] = useState<string>()
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)

	useEffect(() => {
		userService
			.getUserDocuments()
			.then(response => setDocuments(response))
			.finally(() => setIsLoading(false))
	}, [forceRefresh])

	const { filteredDocuments } = useMemo(() => {
		if (isLoading) return { filteredDocuments: [] }
		const { filteredDocuments } = (documents as UserDocument)?.files
			.filter(document => {
				if (documentType === DocumentType.APPOINTMENT) return document.appointmentId !== null
				if (documentType === DocumentType.GENERAL) return document.appointmentId === null
				if (documentType === DocumentType.PERSONAL) return document.uploadSource === 'Platform'
				return true
			})
			.reduce(
				(prev, curr) => {
					if (searchText) {
						const addedBy = curr.createdBy
							? curr.createdBy?.fname + ' ' + curr.createdBy?.lname
							: user?.fname + ' ' + user?.lname
						if (
							(curr.fileName.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
								curr.companyId?.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
								addedBy?.toLowerCase().includes(searchText.toLowerCase())) &&
							(!selectedDate ||
								(selectedDate &&
									DateTime.fromISO(curr.uploadedAt).hasSame(
										DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
										'day'
									)))
						) {
							return { filteredDocuments: [...prev.filteredDocuments, curr] }
						}
					} else if (selectedDate) {
						if (
							DateTime.fromISO(curr.uploadedAt).hasSame(
								DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
								'day'
							)
						) {
							return { filteredDocuments: [...prev.filteredDocuments, curr] }
						}
					} else {
						return { filteredDocuments: [...prev.filteredDocuments, curr] }
					}

					return prev
				},
				{
					filteredDocuments: [] as UserDocument['files']
				}
			)
		return { filteredDocuments }
	}, [documents, searchText, selectedDate, documentType])

	const getSelectedType = (): string => {
		if (documentType === DocumentType.GENERAL) return t(tKey('generalDocuments'))
		if (documentType === DocumentType.APPOINTMENT) return t(tKey('appointmentDocuments'))
		if (documentType === DocumentType.PERSONAL) return t(tKey('myDocuments'))
		return t(tKey('allDocuments'))
	}

	const renderDocumentsTBody = (documentsData: UserDocument['files']) => {
		return (
			<tbody className="bg-white">
				{documentsData.map(file => {
					const getFileType = (name: string) => {
						const extension = name.split('.').pop()?.toLowerCase()
						if (extension === 'pdf') return 'pdf'
						if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(extension || ''))
							return 'image'
						return 'doc'
					}

					const fileType = getFileType(file.fileName)
					return (
						<tr className="card-shadow rounded-lg" key={file._id}>
							<td className="tw-table-td rounded-l-lg border-l border-[#26B6A64D]">
								<div className="flex gap-x-5 items-center">
									<div className="px-1.5 py-[3px] rounded bg-[#F6F8FB] border border-[#EEE]">
										{fileType === 'image' ? (
											<img
												src={file.fileURL}
												alt={file.fileName}
												className="h-16 w-16 object-cover"
											/>
										) : fileType === 'pdf' ? (
											<Document
												file={file.fileURL}
												onLoadError={error => console.error('Error loading PDF:', error)}
												loading={<div>Loading...</div>}
												className="h-16 w-16 overflow-hidden">
												<Page
													pageNumber={1}
													width={90}
													height={80}
													renderAnnotationLayer={false}
													renderTextLayer={false}
												/>
											</Document>
										) : (
											<img src={DocumentPreviewIcon} alt="Document Icon" className="h-16 w-16" />
										)}
									</div>
									{file.fileName}
								</div>
							</td>
							<td className="tw-table-td">
								{file.createdBy
									? file.createdBy.fname + ' ' + file.createdBy.lname
									: user.fname + ' ' + user.lname}
							</td>
							{documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL && (
								<td className="tw-table-td">{file.companyId?.company_name}</td>
							)}
							{documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL && (
								<td className="tw-table-td">
									<Link to={`/user/appointments/details/${file.appointmentId?._id}`}>
										{file.appointmentId?.appointmentNumber.toString().padStart(7, '0')}
									</Link>
								</td>
							)}
							<td className="tw-table-td">
								<div className="flex flex-col">
									<span className="text-base text-ellipsis line-clamp-1">
										{DateTime.fromISO(file.uploadedAt)
											.setLocale(appLanguage)
											.toFormat('dd LLL yyyy')}
									</span>
									<span className="text-secondary font-normal text-center">
										{DateTime.fromISO(file.uploadedAt).toFormat('HH:mm')}
									</span>
								</div>
							</td>
							<td className="tw-table-td border-r border-[#26B6A64D] rounded-r-lg">
								<Link download={file.fileName} to={file.fileURL} target="__blank">
									<div className="hover:bg-[#12325814] py-3 px-2.5 w-fit">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none">
											<path
												d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
												stroke="#123258"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.25 7.5L9 11.25L12.75 7.5"
												stroke="#123258"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M9 11.25V2.25"
												stroke="#123258"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</Link>
							</td>
						</tr>
					)
				})}
			</tbody>
		)
	}

	const menuOptions = [
		{ label: t(tKey('allDocuments')), value: DocumentType.ALL },
		{ label: t(tKey('generalDocuments')), value: DocumentType.GENERAL },
		{ label: t(tKey('appointmentDocuments')), value: DocumentType.APPOINTMENT }
	]

	if (isMobileFilterOpen) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="py-4 px-5 grid grid-cols-3">
					<ChevronLeftIcon
						className="w-4 h-4 cursor-pointer"
						onClick={() => setIsMobileFilterOpen(false)}
					/>
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('filters'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-5">
					<InputDate
						placeholder={t(tKey('date'))}
						value={selectedDate}
						onDateChange={(value: string) => setSelectedDate(value)}
						className="w-full p-4 rounded"
					/>
					<select
						name="type"
						onChange={e => setDocumentType(e.target.value as DocumentType)}
						value={documentType}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#5964D3] focus:ring-0">
						{menuOptions.map(option => (
							<option value={option.value} key={option.label}>
								{option.label}
							</option>
						))}
					</select>
					<div className="self-end flex gap-x-6">
						<button
							onClick={() => {
								setSelectedDate('')
								setDocumentType(DocumentType.ALL)
							}}
							className="text-primary font-bold text-sm">
							{t(tKey('reset'))}
						</button>
						<Button onClick={() => setIsMobileFilterOpen(false)} className="font-bold text-sm">
							{t(tKey('apply'))}
						</Button>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			{showModal && (
				<UploadModal
					onRefresh={() => setForceRefresh(prev => !prev)}
					onClose={() => setShowModal(false)}
				/>
			)}
			{isLoading && (
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black font-bold -ml-9 text-xl animate-pulse">
							Please Wait...
						</span>
					</div>
				</div>
			)}
			<div className="lg:px-[60px] px-5 pt-5 bg-[#f0eff3] min-h-screen lg:py-8 max-lg:pb-[90px] lg:min-h-screen">
				<div className="flex flex-col gap-y-5 lg:gap-y-8">
					<div className="flex justify-between items-center">
						<h1 className="text-primary font-domine lg:text-2xl font-bold">
							{t(tKey('tabs.documents'))}
						</h1>
						<div className="flex gap-x-4 items-center">
							<div className="relative max-lg:hidden flex items-center">
								<input
									name="document"
									placeholder={t(tKey('document'))}
									className="rounded-full placeholder:text-secondary text-primary focus:outline-none border-[#D3E3F1] focus:ring-0 focus:border-[#5964D3] search-shadow px-4 py-[5px] w-[322px]"
									onChange={event => setSearchText(event.target.value)}
								/>
								<MagnifyingGlassIcon className="absolute h-6 w-6 right-3 stroke-primary stroke-2" />
							</div>

							{isMobile && (
								<div
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									onClick={() => {
										if (isSearchVisible) {
											setSearchText('')
											return setIsSearchVisible(false)
										}
										setIsSearchVisible(true)
									}}
									className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
										'bg-[#1232584D]': isSearchVisible
									})}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<g clipPath="url(#clip0_2734_2383)">
											<path
												d="M12.9162 11.6672H12.2579L12.0245 11.4422C13.0245 10.2755 13.5412 8.68386 13.2579 6.99219C12.8662 4.67552 10.9329 2.82552 8.59954 2.54219C5.07454 2.10885 2.10788 5.07552 2.54121 8.60052C2.82454 10.9339 4.67455 12.8672 6.99121 13.2589C8.68288 13.5422 10.2745 13.0255 11.4412 12.0255L11.6662 12.2589V12.9172L15.2079 16.4589C15.5495 16.8005 16.1079 16.8005 16.4495 16.4589C16.7912 16.1172 16.7912 15.5589 16.4495 15.2172L12.9162 11.6672ZM7.91621 11.6672C5.84121 11.6672 4.16621 9.99219 4.16621 7.91719C4.16621 5.84219 5.84121 4.16719 7.91621 4.16719C9.99121 4.16719 11.6662 5.84219 11.6662 7.91719C11.6662 9.99219 9.99121 11.6672 7.91621 11.6672Z"
												fill="#123258"
											/>
										</g>
										<defs>
											<clipPath id="clip0_2734_2383">
												<rect width="20" height="20" fill="white" />
											</clipPath>
										</defs>
									</svg>
								</div>
							)}
							{isMobile && (
								<div
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									onClick={() => setIsMobileFilterOpen(true)}
									className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
										'bg-[#1232584D]': documentType !== DocumentType.ALL
									})}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<path
											d="M4.16699 17.5V7.5M15.8337 2.5V5.83333V2.5ZM15.8337 17.5V9.16667V17.5ZM10.0003 2.5V12.5V2.5ZM10.0003 17.5V15.8333V17.5ZM4.16699 2.5V4.16667V2.5Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M14.1667 7.49968C14.1667 8.42015 14.9129 9.16634 15.8333 9.16634C16.7538 9.16634 17.5 8.42015 17.5 7.49968C17.5 6.5792 16.7538 5.83301 15.8333 5.83301C14.9129 5.83301 14.1667 6.5792 14.1667 7.49968Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M8.33366 14.1667C8.33366 15.0871 9.07985 15.8333 10.0003 15.8333C10.9208 15.8333 11.667 15.0871 11.667 14.1667C11.667 13.2462 10.9208 12.5 10.0003 12.5C9.07985 12.5 8.33366 13.2462 8.33366 14.1667Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M2.49967 5.83366C2.49967 6.75413 3.24587 7.50033 4.16634 7.50033C5.08682 7.50033 5.83301 6.75413 5.83301 5.83366C5.83301 4.91318 5.08682 4.16699 4.16634 4.16699C3.24587 4.16699 2.49967 4.91318 2.49967 5.83366Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
									</svg>
								</div>
							)}
							{!isMobile && (
								<InputDate
									placeholder={t(tKey('date'))}
									value={selectedDate}
									onDateChange={(value: string) => setSelectedDate(value)}
									className="rounded-full py-1.5 "
								/>
							)}
							{!isMobile && (
								<MenuDropdown
									options={[
										t(tKey('allDocuments')),
										t(tKey('generalDocuments')),
										t(tKey('appointmentDocuments'))
									]}
									setActive={(value: string) => {
										if (value === t(tKey('generalDocuments')))
											return setDocumentType(DocumentType.GENERAL)
										if (value === t(tKey('appointmentDocuments')))
											return setDocumentType(DocumentType.APPOINTMENT)
										if (value === t(tKey('myDocuments')))
											return setDocumentType(DocumentType.PERSONAL)
										return setDocumentType(DocumentType.ALL)
									}}
									selected={getSelectedType()}
								/>
							)}
						</div>
					</div>
					{!isMobile && (
						<Table
							headers={
								documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL
									? [
											t(tKey('documentName')),
											t(tKey('addedBy')),
											t(tKey('companyName')),
											t(tKey('appointmentId')),
											t(tKey('date')),
											''
										]
									: [t(tKey('documentName')), t(tKey('addedBy')), t(tKey('date')), '']
							}
							items={filteredDocuments?.sort(
								(a, b) =>
									DateTime.fromISO(b.uploadedAt).toMillis() -
									DateTime.fromISO(a.uploadedAt).toMillis()
							)}
							renderComponent={renderDocumentsTBody}
						/>
					)}
					<div className="flex flex-col gap-y-4">
						{isSearchVisible && (
							<div className="relative lg:hidden flex items-center">
								<input
									name="document"
									placeholder={t(tKey('document'))}
									autoFocus
									className="rounded w-full placeholder:text-secondary text-primary focus:outline-none border-[#D3E3F1] focus:ring-0 focus:border-[#5964D3] search-shadow px-4 py-[5px]"
									onChange={event => setSearchText(event.target.value)}
								/>
								<XMarkIcon
									onClick={() => {
										setSearchText('')
										setIsSearchVisible(false)
									}}
									className="absolute h-6 w-6 right-3 stroke-primary stroke-2"
								/>
							</div>
						)}
						{isMobile &&
							filteredDocuments
								?.sort(
									(a, b) =>
										DateTime.fromISO(b.uploadedAt).toMillis() -
										DateTime.fromISO(a.uploadedAt).toMillis()
								)
								.map(documentData => (
									<DocumentCard
										key={documentData._id}
										name={documentData.fileName}
										url={documentData.fileURL}
										addedBy={
											documentData.createdBy
												? documentData.createdBy.fname + ' ' + documentData.createdBy.lname
												: user.fname + ' ' + user.lname
										}
										appointmentId={documentData.appointmentId}
										companyName={documentData.companyId?.company_name}
									/>
								))}
					</div>
				</div>
			</div>
			<Footer className="max-lg:mb-16 max-lg:-mt-10" />
			<MobileNavigation />
		</AppLayout>
	)
}
