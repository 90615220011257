import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react'

import { ReactComponent as BlueEllipseIcon } from 'assets/svgs/blue-ellipse.svg'
import { ReactComponent as PinkEllipseIcon } from 'assets/svgs/pink-ellipse.svg'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import AppointmentSteps from 'components/home/appointment-steps'
import ArticleSection from 'components/home/article-section'
import { FAQ } from 'components/home/faq'
import PromotionSection from 'components/home/promotion-section'
import ProvidersSection from 'components/home/provider-section'
import { SearchBox } from 'components/home/search-box'
import { SubscriptionBox } from 'components/home/subscription-box'
import TestimonialSection from 'components/home/testimonial-section'
import WhyChooseSection from 'components/home/why-choose-section'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

import {
	AdvertisingCompany,
	AdvertisingLogo,
	AdvertisingTwo,
	FaceSerum,
	HomeCarousel1,
	HomeCarousel2,
	HomeCarousel3,
	HomeCarousel4,
	NewEllipseImage
} from 'assets/icons'

import { ArrowRightIcon } from '@heroicons/react/24/outline'
import CountUp from 'react-countup'
import './slider.css'

export const Home = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const swiperRef = useRef<SwiperRef>(null)
	const prevRef = useRef<HTMLDivElement>(null)
	const nextRef = useRef<HTMLDivElement>(null)

	const auth = useAppSelector(state => state.auth)

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

	const tKey = getTKey('home')
	const companyId = searchParams.get('id')

	const statistics = [
		{ title: t(tKey('labels.appointmentsBookedToday')), stat: 100 },
		{ title: t(tKey('labels.partnerBusiness')), stat: 25 },
		{ title: t(tKey('labels.stylists')), stat: 2500 }
	]

	const handleBarClick = (index: number) => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slideTo(index)
		}
	}

	return (
		<AppLayout
			onLogoClick={() => navigate(`/company-details/${companyId}`)}
			renderDashboardHeader={!!auth.accessToken}
			renderHomeHeader={!auth.accessToken}>
			<div className="flex flex-col min-h-screen">
				<div className="flex-grow">
					{!isMobile && (
						<div className="overflow-hidden relative flex max-lg:hidden">
							<div className="flex flex-col bg-coverRectangle basis-3/5 bg-center bg-no-repeat bg-cover items-start px-40 pb-10 justify-between h-full">
								<div className="flex flex-col items-center py-20 gap-[33px] justify-center">
									<div className="flex flex-col relative z-20 gap-3">
										<h5 className="text-[#D2998D] whitespace-nowrap font-semibold italic text-lg">
											{t(tKey('labels.anytime'))}
										</h5>
										<h1 className="text-primary font-bold text-[40px] leading-tight">
											{t(tKey('headings.easyBooking'))}
										</h1>
										<h5
											className="text-primary text-lg"
											dangerouslySetInnerHTML={{
												__html: t(tKey('headings.easyBookingSubtitle'))
											}}></h5>
									</div>
									<SearchBox />
								</div>
							</div>
							<PinkEllipseIcon className="absolute" />
							<BlueEllipseIcon className="absolute translate-x-40 -translate-y-20" />

							<div className="absolute bottom-6 inset-x-0 left-1/2 w-1/2 flex justify-center items-center gap-2.5">
								{Array.from({ length: 4 }).map((_, index) => (
									<div
										key={index}
										className={clsx(
											'h-[3px] w-24',
											index === currentSlideIndex ? 'bg-white' : 'bg-white/50'
										)}></div>
								))}
							</div>

							<div className="basis-2/5 absolute left-1/3 h-full right-0 max-w-full -z-10 top-0">
								<Swiper
									slidesPerView={1}
									loop={true}
									className="h-full"
									ref={swiperRef}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false
									}}
									modules={[Navigation, Autoplay]}
									navigation={{
										prevEl: prevRef.current,
										nextEl: nextRef.current
									}}
									onBeforeInit={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
									}}
									onSlideChange={(swiper: SwiperClass) => {
										setCurrentSlideIndex(swiper.realIndex)
									}}
									onSwiper={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
										swiper.navigation.init()
										swiper.navigation.update()
									}}>
									<SwiperSlide className="w-full h-full">
										<img src={HomeCarousel1} className="object-cover h-full w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full h-full">
										<img src={HomeCarousel2} className="object-cover h-full w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full h-full">
										<img src={HomeCarousel3} className="object-cover h-full w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full h-full">
										<img src={HomeCarousel4} className="object-cover h-full w-full" />
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					)}

					{isMobile && (
						<div className="flex flex-col gap-4 lg:hidden">
							<div className="relative bg-[#E9E0F7] flex flex-col items-start px-5 py-10 justify-between">
								<div className="flex flex-col items-center gap-6 justify-center">
									<div className="flex flex-col gap-[23px] items-center relative z-20">
										<h5 className="text-[#D2998D] font-semibold italic">
											{t(tKey('labels.anytime'))}
										</h5>
										<h1
											className="text-primary font-bold text-center text-[32px] leading-tight"
											dangerouslySetInnerHTML={{
												__html: t(tKey('headings.easyBookingMobile'))
											}}></h1>
										<h5 className="text-primary text-center">
											{t(tKey('headings.easyBookingMobileSubtitle'))}
										</h5>
									</div>
									<SearchBox />
								</div>
								<PinkEllipseIcon className="absolute size-[90%] left-0" />
								<BlueEllipseIcon className="absolute size-[90%] right-0" />
							</div>

							<div>
								<Swiper
									slidesPerView={1}
									loop={true}
									ref={swiperRef}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false
									}}
									modules={[Navigation, Autoplay]}
									className="relative"
									navigation={{
										prevEl: prevRef.current,
										nextEl: nextRef.current
									}}
									onBeforeInit={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
									}}
									onSlideChange={(swiper: SwiperClass) => {
										setCurrentSlideIndex(swiper.realIndex)
									}}
									onSwiper={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
										swiper.navigation.init()
										swiper.navigation.update()
									}}>
									<div className="absolute bottom-5 mx-6 inset-x-0 z-10 grid grid-cols-4 justify-center items-center gap-2.5">
										{Array.from({ length: 4 }).map((_, index) => (
											<div
												key={index}
												className={clsx(
													'h-[3px] w-full',
													index === currentSlideIndex ? 'bg-white' : 'bg-white/50'
												)}></div>
										))}
									</div>
									<SwiperSlide>
										<img src={HomeCarousel1} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel2} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel3} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel4} />
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					)}

					<div
						style={{
							background: 'linear-gradient(119.36deg, #5166D6 -12.41%, #1A2146 100%)'
						}}
						className="py-6 px-5 md:px-[120px] flex items-center justify-evenly gap-2.5">
						{statistics.map((statsValue, index) => (
							<div key={index} className="flex flex-col items-center gap-1.5">
								<CountUp
									end={statsValue.stat}
									duration={5}
									suffix="+"
									className="text-white text-[32px] leading-[27px] md:leading-[64px] md:text-[40px] font-bold"
								/>
								<h5 className="text-white text-center text-xs md:text-lg">{statsValue.title}</h5>
							</div>
						))}
					</div>

					<div className="container md:h-[950px] mx-auto py-8 px-5 md:px-[50px] md:py-[100px]">
						<div className="grid h-full md:grid-rows-2 md:grid-flow-col grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
							<div className="relative md:col-span-2 flex flex-col overflow-hidden md:row-span-2 rounded-[32px]">
								<img
									src={AdvertisingCompany}
									className="h-full max-md:h-[500px] rounded-t-[32px] object-cover"
								/>
								<div className="bg-gradient-to-b inset-0 from-[#00000000] absolute z-10 to-[#00000080]" />

								<div className="flex items-center absolute justify-center top-0 left-3">
									<img src={NewEllipseImage} />
									<p className="text-white font-domine font-bold text-2xl absolute -translate-y-[18px]">
										{t(tKey('labels.new'))}
									</p>
								</div>
								<button
									onClick={() => navigate('/company-details/6721077b78c843f0ed3d6072')}
									className="bg-[#5964D3] size-[61px] absolute flex items-center justify-center top-5 right-5 text-white rounded-full hover:bg-[#7D8AE6] focus:outline-none">
									<ArrowRightIcon className="-rotate-45 size-[33px]" />
								</button>
								<div className="absolute z-20 flex flex-col gap-3 left-6 bottom-16">
									<img src={AdvertisingLogo} className="rounded-xl size-20 object-cover" />
									<h2 className="text-[33px] text-white leading-10 font-semibold">
										Bürgenstock Resort Lake Lucerne
									</h2>
									<p className="text-2xl font-semibold text-white leading-10">Spa & Wellness</p>
								</div>
								<div className="bg-primary relative z-20 rounded-b-[32px] py-2.5 flex justify-center">
									<p className="text-white uppercase font-semibold">
										{t(tKey('labels.advertising'))}
									</p>
								</div>
							</div>
							<img src={FaceSerum} className="w-full h-full" />
							<div className="bg-[#D39A8E80] w-full h-full rounded-[32px] p-6 flex flex-col gap-3">
								<div className="border border-primary backdrop-blur-sm py-1.5 px-2.5 rounded-full">
									<p className="text-primary font-semibold">{t(tKey('labels.advertising'))}</p>
								</div>
								<p
									className="[&>span]:font-semibold text-[33px] leading-10 text-primary"
									dangerouslySetInnerHTML={{ __html: t(tKey('labels.save')) }}
								/>
							</div>
							<div className="rounded-[32px] overflow-hidden flex flex-col md:row-span-2 relative">
								<img src={AdvertisingTwo} className="rounded-t-[32px] h-full object-cover" />
								<div className="absolute z-10 inset-0 bg-gradient-to-b from-[#1F2A9BB2] to-[#00000000]" />
								<div className="flex flex-col absolute left-5 top-5 z-20 gap-4">
									<p className="uppercase text-white/60 font-semibold">
										{t(tKey('labels.advertising'))}
									</p>
									<div className="flex flex-col gap-2">
										<h2 className="uppercase text-white font-semibold text-[33px] leading-[33px]">
											{t(tKey('labels.mustVisit'))}
										</h2>
										<h5 className="text-white font-semibold text-[26px] leading-[26px]">
											{t(tKey('labels.meetBeauty'))}
										</h5>
									</div>
								</div>
								<div className="bg-primary rounded-b-[32px] py-2.5 flex justify-center">
									<p className="text-white uppercase font-semibold">
										{t(tKey('labels.advertising'))}
									</p>
								</div>
							</div>
							{/* <CategoryCard
								title="Bürgenstock Alpine Spa"
								image={Category4}
								onClick={() => navigate('/company-details/6721077b78c843f0ed3d6072')}
								className="max-md:hidden md:row-span-2"
							/>
							<CategoryCard title="Grand Vista Salon" image={Category2} />
							<CategoryCard title="Best deals in town" image={Article2} />
							<CategoryCard title="Nail Care Salon" image={Category3} className="col-span-2" /> */}
						</div>
					</div>
					<div className="mb-8">
						<AppointmentSteps />
					</div>

					<div className="mb-8">
						<ProvidersSection />
					</div>

					<div className="mb-8">
						<WhyChooseSection />
					</div>

					<div className="mb-8">
						<PromotionSection />
					</div>

					<div className="mb-8">
						<TestimonialSection />
					</div>

					<div className="mb-8">
						<ArticleSection />
					</div>

					<div className="mb-8">
						<FAQ />
					</div>

					<div className="mb-8">
						<SubscriptionBox />
					</div>
				</div>

				<Footer />
			</div>
		</AppLayout>
	)
}
