import axios from 'services/axios'

const getAppointmentStats = (): Promise<AppointmentStat> => {
	return axios.get(`/client/stats`).then(response => response.data.stats)
}

const getAppointmentByUserId = (): Promise<Appointment[]> => {
	return axios.get(`/client/appointments`).then(response => response.data)
}

const getAppointmentById = (appointmentId: string): Promise<Appointment> => {
	return axios.get(`/client/appointments/${appointmentId}`).then(response => response.data)
}

const getAppointmentQuestions = (id: string): Promise<AppointmentQuestion[]> => {
	return axios.get(`/user/fields/${id}?group=questions`).then(response => response.data)
}

const sendEmail = (id: string, data: FormData) => {
	return axios
		.post(`/send-email/${id}`, data, { headers: { 'Content-Type': `multipart/form-data` } })
		.then(response => response.data)
}

const getAppointmentSlots = (
	id: string,
	from: number,
	to: number,
	employeeId?: string
): Promise<{ from: number; to: number }[]> => {
	const params = new URLSearchParams({
		id_company: id,
		from: from.toString(),
		to: to.toString()
	})
	if (employeeId) {
		params.append('id_employee', employeeId)
	}

	return axios.get(`/slots/appointments?${params.toString()}`).then(response => response.data)
}

const getAppointmentsPrescription = (id: string): Promise<Prescription[]> => {
	return axios.get(`/medicationsByAppointmentId/${id}`).then(response => response.data)
}

const getAppointmentDocuments = (id: string): Promise<UserDocument> => {
	return axios.get(`/files/appointment/client/${id}`).then(response => response.data)
}

const updatePaymentStatus = (appointmentId: string, sessionId: string) => {
	return axios
		.put(`/client/payment/${appointmentId}`, { sessionId })
		.then(response => response.data)
}

const createAppointment = (data: AppointmentForm) => {
	return axios.post('/appointments', data).then(response => response.data)
}

const cancelAppointment = (id: string, data: { status: string }) => {
	return axios.put(`/client/cancel/${id}`, data).then(response => response.data)
}

const updateAppointment = (id: string, from: number, to: number) => {
	return axios.put(`/client/appointments/${id}`, { from, to }).then(response => response.data)
}

const appointmentService = {
	getAppointmentStats,
	getAppointmentByUserId,
	getAppointmentById,
	sendEmail,
	getAppointmentQuestions,
	getAppointmentDocuments,
	getAppointmentsPrescription,
	updatePaymentStatus,
	updateAppointment,
	getAppointmentSlots,
	cancelAppointment,
	createAppointment
}

export default appointmentService
