import advertisingLogo from 'assets/jpgs/advertising-logo.png'
import advertisingCompany from 'assets/jpgs/advertising-one.jpg'
import activity from 'assets/pngs/activity.png'
import advertisingTwo from 'assets/pngs/advertising-two.jpeg'
import analyze from 'assets/pngs/analyze.png'
import appBanner from 'assets/pngs/app-banner.png'
import appImage from 'assets/pngs/app.png'
import appleAppStore from 'assets/pngs/apple-app-store.png'
import appleStoreSmall from 'assets/pngs/apple-store-small.png'
import appleStore from 'assets/pngs/apple-store.png'
import appointmentBooked from 'assets/pngs/appointment-booked.png'
import animatedAppointoMobileApp from 'assets/pngs/appointo-animated-app.png'
import appointoApp from 'assets/pngs/appointo-app.png'
import appointoMobileApp from 'assets/pngs/appointo-mobile-app.png'
import article1 from 'assets/pngs/article-1.png'
import article2 from 'assets/pngs/article-2.png'
import article3 from 'assets/pngs/article-3.png'
import articleLeftCircle from 'assets/pngs/article-left-circle.png'
import articleTopCircle from 'assets/pngs/article-top-circle.png'
import blackChevronDown from 'assets/pngs/black-chevron-down.png'
import blueHeart from 'assets/pngs/blue-heart.png'
import bookingExperience from 'assets/pngs/booking-experience.png'
import browse from 'assets/pngs/browse.png'
import businessImage from 'assets/pngs/business.png'
import whiteCalendar from 'assets/pngs/calendar-white.png'
import cashIcon from 'assets/pngs/cash.png'
import category1 from 'assets/pngs/category-1.png'
import category2 from 'assets/pngs/category-2.png'
import category3 from 'assets/pngs/category-3.png'
import category4 from 'assets/pngs/category-4.jpg'
import whiteChat from 'assets/pngs/chat-white.png'
import checkmark from 'assets/pngs/checkmark.png'
import chevronDown from 'assets/pngs/chevron-down.png'
import clipBoard from 'assets/pngs/clipboard.png'
import cloudStorage from 'assets/pngs/cloud-storage.png'
import community from 'assets/pngs/community.png'
import companyBanner from 'assets/pngs/company-banner.png'
import convenienceAtFingertips from 'assets/pngs/convenience-at-your-fingertips.png'
import curvedCorner from 'assets/pngs/curved-corner.png'
import customerSupport from 'assets/pngs/customer-support.png'
import dangerIcon from 'assets/pngs/danger.png'
import dateLogo from 'assets/pngs/date.png'
import deleteIcon from 'assets/pngs/delete.png'
import diabetes from 'assets/pngs/diabetes.png'
import discussion from 'assets/pngs/discussion.png'
import documentPreview from 'assets/pngs/document-preview.png'
import dottedBuilding from 'assets/pngs/dotted-building.png'
import ellipseBottom from 'assets/pngs/ellipse-bottom.png'
import ellipseRight from 'assets/pngs/ellipse-right.png'
import faceSerum from 'assets/pngs/face-serum.png'
import face from 'assets/pngs/face.png'
import flexiblePaymentMethod from 'assets/pngs/flexible-payment-methods.png'
import formBanner from 'assets/pngs/form-banner.png'
import frontScreen from 'assets/pngs/front-screen.png'
import gerieLogo from 'assets/pngs/gerie.png'
import googlePlayStore from 'assets/pngs/google-play-store.png'
import greenHeart from 'assets/pngs/green-heart.png'
import heart from 'assets/pngs/heart.png'
import herzkreislauf from 'assets/pngs/herzkreislauf .png'
import highlight from 'assets/pngs/highlight.png'
import homeLogo from 'assets/pngs/home-logo.png'
import magnifyingGlass from 'assets/pngs/magnifying-glass.png'
import mapPinIcon from 'assets/pngs/map-pin.png'
import mobileApp from 'assets/pngs/mobile-app.png'
import mobileFrontScreen from 'assets/pngs/mobile-front-screen.png'
import mobileStethoscope from 'assets/pngs/mobile-stethoscope.png'
import newEllipse from 'assets/pngs/new-ellipse.png'
import noInbox from 'assets/pngs/no-inbox.png'
import noMessage from 'assets/pngs/no-message.png'
import onlineIcon from 'assets/pngs/online.png'
import whiteOptions from 'assets/pngs/options-white.png'
import person from 'assets/pngs/person.png'
import playStoreSmall from 'assets/pngs/play-store-small.png'
import playStore from 'assets/pngs/play-store.png'
import pregnancy from 'assets/pngs/pregnancy.png'
import privacyHub from 'assets/pngs/privacy-hub.png'
import privacy from 'assets/pngs/privacy.png'
import profilePhoto from 'assets/pngs/profile-photo.png'
import profile from 'assets/pngs/profile.png'
import provider1 from 'assets/pngs/provider-1.png'
import provider2 from 'assets/pngs/provider-2.png'
import provider3 from 'assets/pngs/provider-3.png'
import provider4 from 'assets/pngs/provider-4.png'
import provider5 from 'assets/pngs/provider-5.png'
import provider6 from 'assets/pngs/provider-6.png'
import qrCode from 'assets/pngs/qrcode.jpg'
import questionMark from 'assets/pngs/question-mark.png'
import rectangle from 'assets/pngs/rectangle.png'
import relatedPerson from 'assets/pngs/related-person.png'
import relax from 'assets/pngs/relax.png'
import whiteRemote from 'assets/pngs/remote-white.png'
import resolutSignature from 'assets/pngs/resolut-signature.png'
import searchBanner from 'assets/pngs/search-banner.png'
import secureReliable from 'assets/pngs/secure-and-reliable.png'
import security from 'assets/pngs/security.png'
import smsLogo from 'assets/pngs/sms.png'
import step1 from 'assets/pngs/step-1.png'
import step2 from 'assets/pngs/step-2.png'
import step3 from 'assets/pngs/step-3.png'
import stepLine from 'assets/pngs/step-line.png'
import stethoscope from 'assets/pngs/stethoscope.png'
import study from 'assets/pngs/study.png'
import stylingCornerColor from 'assets/pngs/styling-corner-color.png'
import testimonialBackground from 'assets/pngs/testimonial-bg.png'
import testimonialClient from 'assets/pngs/testimonial-client.png'
import thumbnail from 'assets/pngs/thumbnail.png'
import topPharmLogo from 'assets/pngs/top-pharm.png'
import transparentHeart from 'assets/pngs/transparent-heart.png'
import transparentIcon from 'assets/pngs/transparent-icon.png'
import whyChooseUs1 from 'assets/pngs/why-choose-us-1.png'
import whyChooseUs2 from 'assets/pngs/why-choose-us-2.png'

import appointoGreen from 'assets/svgs/appointo-green.svg'
import appointoWhite from 'assets/svgs/appointo-white.svg'
import baby from 'assets/svgs/baby.svg'
import brandGlobe from 'assets/svgs/brand-globe.svg'
import brandLocation from 'assets/svgs/brand-location.svg'
import brandMail from 'assets/svgs/brand-mail.svg'
import brandPhone from 'assets/svgs/brand-phone.svg'
import calendar from 'assets/svgs/calendar.svg'
import camera from 'assets/svgs/camera.svg'
import chat from 'assets/svgs/chat.svg'
import checkCircle from 'assets/svgs/check.svg'
import circle from 'assets/svgs/circle.svg'
import clock from 'assets/svgs/clock.svg'
import currentCircle from 'assets/svgs/current.svg'
import download from 'assets/svgs/download.svg'
import dustbin from 'assets/svgs/dustbin.svg'
import exclamationMark from 'assets/svgs/exclamation-mark.svg'
import eyeBags from 'assets/svgs/eye-bags.svg'
import facebook from 'assets/svgs/facebook.svg'
import github from 'assets/svgs/github.svg'
import globe from 'assets/svgs/globe.svg'
import homeBackground from 'assets/svgs/home-background.svg'
import hypoallergenic from 'assets/svgs/hypoallergenic.svg'
import infoCircle from 'assets/svgs/info-cirlce.svg'
import inputCalendar from 'assets/svgs/input-calendar.svg'
import instagram from 'assets/svgs/instagram.svg'
import navigation from 'assets/svgs/navigation.svg'
import outlineGlobe from 'assets/svgs/outline-globe.svg'
import outlineLocation from 'assets/svgs/outline-location.svg'
import outlineMail from 'assets/svgs/outline-mail.svg'
import outlinePhone from 'assets/svgs/outline-phone.svg'
import pencil from 'assets/svgs/pencil.svg'
import playButton from 'assets/svgs/play-button.svg'
import reminder from 'assets/svgs/reminder.svg'
import remote from 'assets/svgs/remote.svg'
import sendButton from 'assets/svgs/send-button.svg'
import socialFacebook from 'assets/svgs/social-facebook.svg'
import socialInstagram from 'assets/svgs/social-instagram.svg'
import socialLinkedin from 'assets/svgs/social-linkedin.svg'
import socialTwitter from 'assets/svgs/social-twitter.svg'
import socialYoutube from 'assets/svgs/social-youtube.svg'
import tooth from 'assets/svgs/tooth.svg'
import twitter from 'assets/svgs/twitter.svg'
import ultrasound from 'assets/svgs/ultrasound.svg'

import homeCarousel1 from 'assets/jpgs/home-carousel-1.jpg'
import homeCarousel2 from 'assets/jpgs/home-carousel-2.jpg'
import homeCarousel3 from 'assets/jpgs/home-carousel-3.jpg'
import homeCarousel4 from 'assets/jpgs/home-carousel-4.jpg'
import homeCarousel5 from 'assets/jpgs/home-carousel-5.jpg'

export const AdvertisingCompany = advertisingCompany
export const AdvertisingLogo = advertisingLogo
export const AdvertisingTwo = advertisingTwo
export const AppImage = appImage
export const AppointmentBooked = appointmentBooked
export const AppointoGreenIcon = appointoGreen
export const AppointoWhiteIcon = appointoWhite
export const AppointoMobileApp = appointoMobileApp
export const AnimatedAppointoMobileApp = animatedAppointoMobileApp
export const AppointoApp = appointoApp
export const BookingExperience = bookingExperience
export const BusinessImage = businessImage
export const ConvenienceAtYourFingertips = convenienceAtFingertips
export const CustomerSupport = customerSupport
export const FlexiblePaymentMethod = flexiblePaymentMethod
export const SecureAndReliable = secureReliable
export const QrCodeIcon = qrCode
export const PlayStoreIcon = playStore
export const AppleStoreIcon = appleStore
export const MagnifyingGlassIcon = magnifyingGlass
export const ScreenIcon = screen
export const CurvedCornerIcon = curvedCorner
export const SmallPlayStoreIcon = playStoreSmall
export const SmallAppStoreIcon = appleStoreSmall
export const SignatureIcon = resolutSignature
export const SMSIcon = smsLogo
export const FrontScreenIcon = frontScreen
export const DiscussionIcon = discussion
export const DeleteIcon = deleteIcon
export const AnalyzeIcon = analyze
export const ActivityIcon = activity
export const CommunityIcon = community
export const GreenHeartIcon = greenHeart
export const PrivacyIcon = privacy
export const PersonIcon = person
export const HighlightIcon = highlight
export const BrowseIcon = browse
export const StudyIcon = study
export const RelaxIcon = relax
export const QuestionMarkIcon = questionMark
export const MapPinIcon = mapPinIcon
export const MobileAppIcon = mobileApp
export const MobileFrontScreenIcon = mobileFrontScreen
export const ThumbnailIcon = thumbnail
export const SearchBanner = searchBanner
export const FaceIcon = face
export const WhiteRemoteIcon = whiteRemote
export const WhiteCalendarIcon = whiteCalendar
export const WhiteChatIcon = whiteChat
export const WhiteOptionsIcon = whiteOptions
export const HeartIcon = heart
export const RectangleIcon = rectangle
export const DottedBuildingIcon = dottedBuilding
export const DateIcon = dateLogo
export const TopPharmIcon = topPharmLogo
export const GerieIcon = gerieLogo
export const ProfileIcon = profile
export const CompanyBannerIcon = companyBanner
export const ChevronDownIcon = chevronDown
export const BlackChevronDownIcon = blackChevronDown
export const FormBannerIcon = formBanner
export const EllipseRightIcon = ellipseRight
export const EllipseBottomIcon = ellipseBottom
export const CheckmarkIcon = checkmark
export const StethoscopeIcon = stethoscope
export const InfoCircleIcon = infoCircle
export const MobileStethoscopeIcon = mobileStethoscope
export const NewEllipseImage = newEllipse
export const FaceSerum = faceSerum
export const NoMessagesIcon = noMessage
export const NoInboxIcon = noInbox
export const ProfilePhotoIcon = profilePhoto
export const RelatedPersonIcon = relatedPerson
export const DangerIcon = dangerIcon
export const BlueHeartIcon = blueHeart
export const HerzkreislaufIcon = herzkreislauf
export const DiabetesIcon = diabetes
export const PregnancyIcon = pregnancy
export const AppBannerIcon = appBanner
export const TransparentHeartIcon = transparentHeart
export const SendButtonIcon = sendButton
export const PrivacyHubIcon = privacyHub
export const ClipBoardIcon = clipBoard
export const CloudStorageIcon = cloudStorage
export const SecurityIcon = security
export const DocumentPreviewIcon = documentPreview
export const TransparentIcon = transparentIcon
export const HomeLogo = homeLogo
export const Article1 = article1
export const Article2 = article2
export const Article3 = article3
export const ArticleLeftCircle = articleLeftCircle
export const ArticleTopCircle = articleTopCircle
export const Category1 = category1
export const Category2 = category2
export const Category3 = category3
export const Category4 = category4
export const TestimonialBackground = testimonialBackground
export const TestimonialClient = testimonialClient
export const GooglePlayStore = googlePlayStore
export const AppleAppStore = appleAppStore
export const WhyChooseUs1 = whyChooseUs1
export const WhyChooseUs2 = whyChooseUs2
export const Step1 = step1
export const Step2 = step2
export const Step3 = step3
export const StepLine = stepLine
export const Provider1 = provider1
export const Provider2 = provider2
export const Provider3 = provider3
export const Provider4 = provider4
export const Provider5 = provider5
export const Provider6 = provider6

export const BabyIcon = baby
export const StylingCornerColorIcon = stylingCornerColor
export const EyeBagIcon = eyeBags
export const HypoAllergenicIcon = hypoallergenic
export const ToothIcon = tooth
export const UltrasoundIcon = ultrasound
export const FacebookIcon = facebook
export const InstagramIcon = instagram
export const TwitterIcon = twitter
export const GithubIcon = github
export const GlobeIcon = globe
export const ReminderIcon = reminder
export const RemoteIcon = remote
export const CalendarIcon = calendar
export const CashIcon = cashIcon
export const OnlineIcon = onlineIcon
export const ChatIcon = chat
export const PlayButtonIcon = playButton
export const InputCalendarIcon = inputCalendar
export const ClockIcon = clock
export const DownloadIcon = download
export const NavigationIcon = navigation
export const OutlineMailIcon = outlineMail
export const OutlinePhoneIcon = outlinePhone
export const OutlineLocationIcon = outlineLocation
export const OutlineGlobeIcon = outlineGlobe
export const BrandMailIcon = brandMail
export const BrandPhoneIcon = brandPhone
export const BrandLocationIcon = brandLocation
export const BrandGlobeIcon = brandGlobe
export const CircleCheckIcon = checkCircle
export const CircleCurrentIcon = currentCircle
export const CircleIcon = circle
export const ExclamationMarkIcon = exclamationMark
export const SocialFacebookIcon = socialFacebook
export const SocialTwitterIcon = socialTwitter
export const SocialYoutubeIcon = socialYoutube
export const SocialInstagramIcon = socialInstagram
export const SocialLinkedinIcon = socialLinkedin
export const CameraIcon = camera
export const PencilIcon = pencil
export const DustbinIcon = dustbin
export const HomeBackground = homeBackground

export const HomeCarousel1 = homeCarousel1
export const HomeCarousel2 = homeCarousel2
export const HomeCarousel3 = homeCarousel3
export const HomeCarousel4 = homeCarousel4
export const HomeCarousel5 = homeCarousel5
