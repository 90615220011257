import { CalendarIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react'

import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Footer } from 'components/home/footer'
import articleService from 'services/articles-service'
import { getAppLang, getTKey } from 'utils/language'

export const Articles = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const swiperRef = useRef<SwiperRef>(null)

	const tKey = getTKey('articles')
	const auth = useAppSelector(state => state.auth)

	const [articles, setArticles] = useState<Article[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

	useEffect(() => {
		articleService
			.getAllArticles()
			.then(response => setArticles(response))
			.finally(() => setIsLoading(false))
	}, [])

	const handleBarClick = (index: number) => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slideTo(index)
		}
	}

	if (isLoading) {
		return (
			<AppLayout renderHomeHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
				<div className="fixed top-1/2 -translate-y-1/2 h-full w-full left-1/2 -translate-x-1/2 bg-black bg-opacity-70 z-50">
					<div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderHomeHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="md:pt-8 md:py-14 md:px-[91px] flex flex-col md:gap-14">
				<div className="flex flex-col gap-y-8 md:gap-y-6">
					<div className="max-md:pt-4 max-md:px-5 flex flex-col gap-y-3.5 md:gap-y-3">
						<h1 className="text-primary text-2xl md:text-[56px] font-domine font-bold leading-8 md:leading-[64px]">
							{t(tKey('headings.articles'))}
						</h1>
						<p className="text-primary text-base md:text-[26px] leading-6 md:leading-[39px]">
							{t(tKey('labels.read'))}
						</p>
					</div>

					<div className="w-full relative">
						<Swiper
							slidesPerView={1}
							loop={true}
							ref={swiperRef}
							autoplay={{
								delay: 2500,
								disableOnInteraction: false
							}}
							modules={[Autoplay]}
							onSlideChange={(swiper: SwiperClass) => {
								setCurrentSlideIndex(swiper.realIndex)
							}}>
							{articles.map(article => (
								<SwiperSlide
									style={{ boxShadow: '0px 4px 24px 0px #7F9AB233' }}
									onClick={() => navigate(`/articles/${article._id}`)}
									className="md:rounded-[32px] cursor-pointer overflow-hidden h-[486px] relative border border-[#E9F1F8]"
									key={article._id}>
									<img src={article.image} alt="article-image" className="object-cover w-full" />
									<div
										style={{
											background:
												'linear-gradient(180deg, rgba(217, 217, 217, 0) 5.3%, rgba(7, 0, 39, 0.255464) 24.06%, rgba(7, 0, 39, 0.59) 48.62%, #070027 94.62%)'
										}}
										className="absolute h-full z-10 bottom-0 w-full"
									/>
									<div className="absolute left-[33px] md:left-[74px] max-md:mr-[33px] md:w-[40%] bottom-[59px] z-20 flex flex-col gap-6">
										<h1 className="font-domine font-bold text-[28px] leading-10 text-white">
											{article.title}
										</h1>
										<p className="line-clamp-2 text-white">{article.overviewText}</p>
										<div className="flex items-center gap-2.5 md:gap-3">
											<CalendarIcon className="size-6 text-primary-light" />
											<p className="text-white">
												{DateTime.fromISO(article.dateUpdated)
													.setLocale(getAppLang())
													.toFormat('LLLL dd,yyyy')}
											</p>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
						<div className="absolute z-30 bottom-6 inset-x-0 -translate-x-1/2 left-1/2 w-1/2 flex justify-center items-center gap-2.5">
							{Array.from({ length: articles.length }).map((_, index) => (
								<div
									key={index}
									className={clsx(
										'h-[3px] w-[86px]',
										index === currentSlideIndex ? 'bg-[#B790D8]' : 'bg-white'
									)}></div>
							))}
						</div>
					</div>
				</div>
				<h1 className="font-bold max-md:py-6 max-md:px-5 text-2xl md:text-[32px] leading-8 md:leading-[48px] text-primary">
					{t(tKey('headings.trendingArticles'))}
				</h1>
				<div className="grid grid-cols-1 max-md:px-5 max-md:pb-6 md:grid-cols-2 gap-8">
					{articles.map(article => (
						<ArticleCard
							key={article._id}
							date={article.dateUpdated}
							title={article.title}
							content={article.overviewText}
							imageUrl={article.image}
							onClick={() => navigate(`/articles/${article._id}`)}
						/>
					))}
				</div>
				<h1 className="font-bold text-2xl max-md:py-6 max-md:px-5 md:text-[32px] leading-8 md:leading-[48px] text-primary">
					{t(tKey('headings.latestArticles'))}
				</h1>
				<div className="grid grid-cols-1 max-md:px-5 max-md:pb-14 md:grid-cols-2 gap-8">
					{articles
						.sort(
							(a, b) =>
								DateTime.fromISO(a.dateUpdated).toMillis() -
								DateTime.fromISO(b.dateUpdated).toMillis()
						)
						.slice(0, 2)
						.map(article => (
							<ArticleCard
								key={article._id}
								date={article.dateUpdated}
								title={article.title}
								content={article.overviewText}
								imageUrl={article.image}
								onClick={() => navigate(`/articles/${article._id}`)}
							/>
						))}
				</div>
			</div>
			<Footer />
		</AppLayout>
	)
}

interface ArticleCardProps {
	date: string
	imageUrl: string
	title: string
	content: string
	onClick: () => void
}

export const ArticleCard = ({ date, imageUrl, title, content, onClick }: ArticleCardProps) => {
	return (
		<div
			onClick={onClick}
			className="md:p-8 p-4 cursor-pointer rounded-[32px] bg-[#E9F1F8] flex flex-col gap-6 md:gap-10">
			<div
				style={{ backgroundImage: 'linear-gradient(to right, #ffbcc6, #d1c4ff)' }}
				className="border-[12px] overflow-hidden rounded-[200px] h-[170px] md:h-[250px]">
				<img src={imageUrl} alt={title} className="object-cover" />
			</div>
			<div className="flex flex-col gap-3 md:gap-6">
				<div className="flex items-center gap-6">
					<div className="flex items-center gap-2.5">
						<CalendarIcon className="text-primary-light size-6" />
						<p className="text-primary">
							{DateTime.fromISO(date).setLocale(getAppLang()).toFormat('dd. LLLL yyyy')}
						</p>
					</div>
				</div>
				<h1 className="font-domine font-bold text-[#123258] text-xl md:text-2xl">{title}</h1>
				<p className="text-primary leading-6 line-clamp-2">{content}</p>
			</div>
		</div>
	)
}
