import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

export const SubscriptionBox = () => {
	const { t } = useTranslation()

	const tKey = getTKey('home')
	return (
		<div className="bg-[#070027] text-white py-8 px-6 md:p-8 rounded-xl flex flex-col md:flex-row items-center justify-between w-full max-w-7xl mx-auto">
			<div className="mb-6 w-full md:mb-0 text-left">
				<h2 className="text-2xl md:text-4xl font-bold">{t(tKey('headings.getLatestUpdates'))}</h2>
				<p
					className="text-md md:text-md mt-2"
					dangerouslySetInnerHTML={{ __html: t(tKey('labels.shareDetails')) }}
				/>
			</div>

			<div className="flex items-center w-full md:w-[50%]">
				<input
					type="email"
					placeholder={t(tKey('placeholders.enterEmail'))}
					className="w-full p-3 rounded-lg text-black outline-none"
				/>
				<button className="bg-[#5964D3] text-white py-3 px-6 ml-4 rounded-lg hover:bg-[#7D8AE6] focus:outline-none shadow-lg">
					{t(tKey('buttons.subscribe'))}
				</button>
			</div>
		</div>
	)
}
