import { FaDribbble, FaFacebookF, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa'

export const Footer = () => {
	return (
		<footer className="bg-[#070027] text-white py-10 w-full">
			<div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center px-4">
				<div className="text-sm text-gray-400 mb-4 md:mb-0">
					© 2020 Workflow, Inc. All rights reserved.
				</div>

				<div className="flex space-x-6 text-gray-400">
					<a href="#" className="hover:text-white">
						<FaFacebookF />
					</a>
					<a href="#" className="hover:text-white">
						<FaInstagram />
					</a>
					<a href="#" className="hover:text-white">
						<FaTwitter />
					</a>
					<a href="#" className="hover:text-white">
						<FaGithub />
					</a>
					<a href="#" className="hover:text-white">
						<FaDribbble />
					</a>
				</div>
			</div>
		</footer>
	)
}
