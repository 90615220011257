import { useTranslation } from 'react-i18next'

import {
	HeartIcon,
	WhiteCalendarIcon,
	WhiteChatIcon,
	WhiteOptionsIcon,
	WhiteRemoteIcon
} from 'assets/icons'
import { LanguageMenu } from 'components/app/language-menu'
import { useNavigate } from 'react-router-dom'
import { getTKey } from 'utils/language'

export const Highlights = () => {
	const { t, i18n } = useTranslation()
	const tKey = getTKey('auth.highlights')

	const items = [
		{
			icon: WhiteChatIcon,
			title: t(tKey('smsReminder'))
		},
		{
			icon: WhiteOptionsIcon,
			title: t(tKey('dashboard'))
		},
		{
			icon: WhiteCalendarIcon,
			title: t(tKey('bookingAppointments'))
		},
		{
			icon: WhiteRemoteIcon,
			title: t(tKey('healthlexion'))
		}
	]

	const navigate = useNavigate()

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}
	return (
		<>
			<div className="hidden z-[9999] bg-dottedBuilding fixed top-0 left-0 w-[35%] flex-col h-full pb-9">
				<div className="pt-9 px-9 flex items-center justify-end z-50">
					<LanguageMenu
						value={i18n.language || window.localStorage.i18nextLng}
						onChange={changeLanguage}
						options={['en', 'de']}
						className="bg-white"
					/>
				</div>
				<div className="flex flex-col items-center justify-center h-screen">
					<div className="flex flex-col items-center gap-y-2.5">
						<img src={HeartIcon} className="h-20 w-20" />
						<h2 className="uppercase text-[#FFF] mt-2 text-2xl">Styling Corner</h2>
					</div>
					<div className="flex flex-col gap-y-7 2xl:gap-y-14 mt-14 2xl:mt-20">
						{items.map(item => (
							<div className="flex gap-x-8 items-center" key={item.title}>
								<img src={item.icon} alt={item.title} />
								<h3 className="text-[#FFF] text-xl font-bold">{item.title}</h3>
							</div>
						))}
					</div>
				</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="288"
					height="832"
					viewBox="0 0 288 832"
					className="absolute"
					fill="none">
					<path
						d="M72.2323 831.013C199.041 700.972 417.494 391.949 184.392 369.218C27.0068 353.871 84.9003 518.795 197.53 446.035C340.188 353.876 50.9108 91.9935 -53.3822 1.15409"
						stroke="white"
						strokeOpacity="0.11"
						strokeDasharray="5 5"
					/>
				</svg>
			</div>
			<div className="hidden w-full bg-primary-light rounded-b-xl py-10 px-5 flex-col gap-y-5 2xl:gap-y-11">
				{items.map(item => (
					<div key={item.title} className="flex gap-x-4 items-center">
						<img src={item.icon} alt={item.title} className="h-10 w-10 object-contain" />
						<h3 className="font-bold text-[#FFF]">{item.title}</h3>
					</div>
				))}
			</div>
		</>
	)
}
