import { useTranslation } from 'react-i18next'

import {
	AppImage,
	AppleAppStore,
	BusinessImage,
	GooglePlayStore,
	SmallAppStoreIcon,
	SmallPlayStoreIcon
} from 'assets/icons'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

const PromotionSection = () => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()

	const tKey = getTKey('home')

	return (
		<section className="flex flex-col gap-8 py-12 px-4 md:px-0 md:mx-20 md:gap-[100px] md:justify-center md:items-stretch">
			{/* First Card */}
			<div className="md:grid max-md:flex max-md:flex-col gap-10 w-full overflow-hidden md:grid-cols-2 items-center justify-center md:gap-12">
				<img src={AppImage} className="w-full max-md:hidden object-cover" />
				<div className="flex flex-col gap-10 md:gap-12">
					<div className="flex flex-col gap-[22px]">
						<h1 className="text-primary max-md:text-center font-bold text-[32px] md:text-[40px] leading-tight">
							{t(tKey('headings.downloadAndDiscover'))}
						</h1>
						<p
							className="text-md max-md:text-center text-primary"
							dangerouslySetInnerHTML={{ __html: t(tKey('labels.mobileApp')) }}
						/>
					</div>
					<div className="flex items-center gap-5 w-full md:gap-6">
						<img
							src={isMobile ? SmallPlayStoreIcon : GooglePlayStore}
							alt="Google Play"
							className="md:w-[231px] max-md:max-w-[190px] w-full h-[50px] md:h-[70px] rounded-md"
						/>
						<img
							src={isMobile ? SmallAppStoreIcon : AppleAppStore}
							alt="App Store"
							className="md:w-[231px] max-md:max-w-[190px] w-full h-[47px] md:h-[67px] rounded-md"
						/>
					</div>
				</div>
				<img src={AppImage} className="w-full md:hidden object-cover" />
			</div>

			{/* Second Card */}
			<div className="md:grid max-md:flex max-md:flex-col gap-10 w-full overflow-hidden md:grid-cols-2 items-center justify-center md:gap-12">
				<div className="flex flex-col gap-10 md:gap-12">
					<div className="flex flex-col gap-[22px]">
						<h1 className="text-primary max-md:text-center font-bold text-[32px] md:text-[40px] leading-tight">
							{t(tKey('headings.growBusiness'))}
						</h1>
						<p
							dangerouslySetInnerHTML={{ __html: t(tKey('labels.registerBusiness')) }}
							className="text-md max-md:text-center text-primary"
						/>
					</div>
					<button
						onClick={() => {
							window.location.href = 'https://stylingcorner.pro'
						}}
						className="rounded-full w-fit bg-primary-light py-4 px-6 text-base max-md:mx-auto text-white">
						{t(tKey('buttons.growBusiness'))}
					</button>
				</div>
				<div className="max-md:bg-[#E9F1F8] max-md:rounded-[22px]">
					<img src={BusinessImage} className="w-full object-cover" />
				</div>
			</div>
		</section>
	)
}

export default PromotionSection
