import { Radio, RadioGroup } from '@headlessui/react'

export type RadioOption = {
	label: string
	value: string
	icon?: string
	helperText?: string
}

interface InputRadioGroupProps {
	label: string
	options: RadioOption[]
	selectedOption: string
	onChange: (value: string) => void
}

export const InputRadioGroup = ({
	label,
	options,
	selectedOption,
	onChange
}: InputRadioGroupProps) => {
	return (
		<div className="w-full">
			<div className="w-full">
				<RadioGroup
					value={selectedOption}
					onChange={onChange}
					aria-label={label}
					className="space-y-6">
					{options.map(option => (
						<Radio
							key={option.value}
							value={option.value}
							style={{ boxShadow: '0px 4px 24px 0px #0000001A' }}
							className="group relative bg-[#F5F7FA] overflow-hidden flex cursor-pointer rounded-xl transition focus:outline-none border border-[#CFD8DC80] p-3 lg:px-8 lg:py-6">
							<div className="flex w-full items-center justify-between">
								<div className="flex z-10 gap-x-2.5 lg:gap-x-10 items-center">
									<img src={option.icon} className="max-lg:h-10 max-lg:w-10" />
									<div className="flex flex-col gap-y-2 lg:gap-y-3">
										<p className="font-domine text-sm lg:text-xl text-primary font-bold">
											{option.label}
										</p>
										{option.helperText && (
											<p className="text-primary max-lg:text-sm">{option.helperText}</p>
										)}
									</div>
								</div>
								{selectedOption !== option.value && (
									<div className="lg:h-8 lg:w-8 h-5 w-5 shrink-0 border z-10 border-primary rounded-full bg-white group-data-[checked]:hidden" />
								)}
								{selectedOption === option.value && (
									<div className="lg:h-8 lg:w-8 h-5 w-5 shrink-0 bg-white z-10 group-data-[checked]:flex hidden items-center justify-center border rounded-full border-[#5964D3]">
										<div className="h-2.5 w-2.5 rounded-full bg-[#5964D3]" />
									</div>
								)}
							</div>
							<div className="absolute left-0 bottom-0 translate-y-4">
								<svg
									width="375"
									height="154"
									viewBox="0 0 375 154"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<ellipse opacity="0.37" cx="179.5" cy="189" rx="195.5" ry="194" fill="#CFD8DC" />
								</svg>
							</div>
							<div className="absolute right-0 top-0 -translate-y-4">
								<svg
									width="243"
									height="151"
									viewBox="0 0 243 151"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle opacity="0.37" cx="176" cy="-25" r="176" fill="#CFD8DC" />
								</svg>
							</div>
						</Radio>
					))}
				</RadioGroup>
			</div>
		</div>
	)
}
