import { MapPinIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import { DangerIcon, DustbinIcon, PencilIcon, RelatedPersonIcon } from 'assets/icons'
import { Modal } from 'components/app/modal'
import useIsMobile from 'hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import relationService from 'services/relation-service'
import { getAppLang, getTKey } from 'utils/language'

interface RelatedPersonCardProps {
	id: string
	image?: string
	fname: string
	lname: string
	insuranceNumber: string
	address: string
	birthDate: number
	relation: string
}

export const RelatedPersonCard = ({
	id,
	image = RelatedPersonIcon,
	fname,
	lname,
	insuranceNumber,
	address,
	birthDate,
	relation
}: RelatedPersonCardProps) => {
	const navigate = useNavigate()
	const isMobile = useIsMobile()
	const [showModal, setShowModal] = useState(false)
	const { t } = useTranslation()
	const tKey = getTKey('relatedPersons')

	const appLanguage = getAppLang()

	const handleDelete = (id: string) => {
		relationService
			.deleteRelation(id)
			.then(() => {
				setTimeout(() => {
					toast.success(t(tKey('toast.relationDeleted')))
				}, 500)
				window.location.reload()
			})
			.catch(() => t(tKey('toast.errorRelationDeleted')))
	}
	return (
		<div className="relative bg-white lg:py-8 lg:px-6 p-3 rounded-xl main-search-shadow flex flex-col gap-y-5 lg:w-[426px]">
			{showModal && (
				<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col max-lg:items-center lg:flex-row gap-x-4">
							<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
							<div className="flex max-lg:items-center flex-col gap-y-2">
								<h2 className="font-domine text-lg text-primary">
									{t(tKey('modal.titles.deletePatient'))}
								</h2>
								<p className="text-[#7F9AB2] text-sm">
									{t(tKey('modal.labels.deletePatientProfile'))}
								</p>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row lg:justify-end lg:items-center max-lg:gap-y-5 gap-x-3">
							<button
								onClick={() => setShowModal(false)}
								className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
								{t(tKey('modal.buttons.cancel'))}
							</button>
							<button
								onClick={() => handleDelete(id)}
								className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
								{t(tKey('modal.buttons.delete'))}
							</button>
						</div>
					</div>
				</Modal>
			)}
			<div>
				<div className="max-lg:hidden flex gap-x-2 justify-end -m-4">
					<img
						src={PencilIcon}
						onClick={() => navigate(`/related-persons/edit/${id}`)}
						className="cursor-pointer search-shadow"
					/>
					<img
						src={DustbinIcon}
						onClick={() => setShowModal(true)}
						className="cursor-pointer search-shadow"
					/>
				</div>
				<div className="flex lg:items-center lg:gap-x-5 gap-x-3">
					<img src={image} className="w-[30px] lg:w-[60px] h-[30px] lg:h-[60px]" />
					<div className="flex flex-col gap-y-0.5 lg:gap-y-1">
						<h3 className="text-primary text-sm lg:text-lg font-semibold lg:font-bold">
							{fname + ' ' + lname}
						</h3>
						<span className="text-xs lg:text-sm text-secondary">{insuranceNumber}</span>
					</div>
				</div>
			</div>
			<div className="max-lg:hidden flex gap-x-2">
				<MapPinIcon className="h-4 w-4" />
				<p className="text-sm text-secondary">{address}</p>
			</div>
			<div className="max-lg:hidden border-b border-dashed border-[#D3E3F1]" />
			<div className="max-lg:hidden grid grid-cols-2 gap-x-5">
				<div className="flex flex-col gap-y-2">
					<h5 className="text-sm text-secondary">{t(tKey('labels.dob'))}</h5>
					<span className="text-primary">
						{DateTime.fromMillis(birthDate).setLocale(appLanguage).toFormat('dd.MM.yyyy')}
					</span>
				</div>
				<div className="flex flex-col gap-y-2">
					<h5 className="text-sm text-secondary">{t(tKey('labels.relation'))}</h5>
					<span className="text-primary">{relation}</span>
				</div>
			</div>

			<div className="flex justify-between items-center lg:hidden">
				<div className="flex gap-x-2 items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="17"
						viewBox="0 0 16 17"
						fill="none">
						<path
							d="M9.9231 12.4996C9.53336 12.4996 9.20088 12.3619 8.92565 12.0867C8.65044 11.8115 8.51284 11.479 8.51284 11.0893C8.51284 10.6996 8.65044 10.3671 8.92565 10.0919C9.20088 9.81664 9.53336 9.67904 9.9231 9.67904C10.3128 9.67904 10.6453 9.81664 10.9205 10.0919C11.1957 10.3671 11.3334 10.6996 11.3334 11.0893C11.3334 11.479 11.1957 11.8115 10.9205 12.0867C10.6453 12.3619 10.3128 12.4996 9.9231 12.4996ZM3.7436 14.4996C3.43677 14.4996 3.18058 14.3968 2.97502 14.1912C2.76946 13.9857 2.66669 13.7295 2.66669 13.4226V4.9098C2.66669 4.60297 2.76946 4.34678 2.97502 4.14122C3.18058 3.93566 3.43677 3.83289 3.7436 3.83289H4.92309V2.3457H5.64104V3.83289H10.4103V2.3457H11.077V3.83289H12.2564C12.5633 3.83289 12.8195 3.93566 13.025 4.14122C13.2306 4.34678 13.3334 4.60297 13.3334 4.9098V13.4226C13.3334 13.7295 13.2306 13.9857 13.025 14.1912C12.8195 14.3968 12.5633 14.4996 12.2564 14.4996H3.7436ZM3.7436 13.8329H12.2564C12.359 13.8329 12.453 13.7902 12.5385 13.7047C12.624 13.6192 12.6667 13.5252 12.6667 13.4226V7.57647H3.33335V13.4226C3.33335 13.5252 3.37609 13.6192 3.46155 13.7047C3.54703 13.7902 3.64105 13.8329 3.7436 13.8329ZM3.33335 6.9098H12.6667V4.9098C12.6667 4.80725 12.624 4.71323 12.5385 4.62775C12.453 4.54229 12.359 4.49955 12.2564 4.49955H3.7436C3.64105 4.49955 3.54703 4.54229 3.46155 4.62775C3.37609 4.71323 3.33335 4.80725 3.33335 4.9098V6.9098Z"
							fill="black"
						/>
					</svg>
					<p className="text-xs text-secondary">
						{DateTime.fromMillis(birthDate).setLocale(appLanguage).toFormat('dd.MM.yyyy')}
					</p>
				</div>
				<div className="flex gap-x-2">
					<img
						src={PencilIcon}
						onClick={() => navigate(`/related-persons/edit/${id}`)}
						className="cursor-pointer search-shadow"
					/>
					<img
						onClick={() => setShowModal(true)}
						src={DustbinIcon}
						className="cursor-pointer search-shadow"
					/>
				</div>
			</div>
		</div>
	)
}
