import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import articleService from 'services/articles-service'
import { getAppLang, getTKey } from 'utils/language'
import BlogCard from './blog-card'

const ArticleSection = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('home')

	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		articleService.getAllArticles().then(response => setArticles(response))
	}, [])

	if (articles.length === 0) return <></>

	return (
		<section className="py-12 bg-[#E9F1F8]">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<h2 className="text-[32px] md:text-[40px] font-bold text-center text-black mb-4">
					{t(tKey('headings.articles'))}
				</h2>
				<p className="text-center text-md text-gray-500 mb-12">{t(tKey('labels.articles'))}</p>
				<div className="flex flex-wrap justify-center gap-8">
					{articles.map((article, index) => (
						<BlogCard
							key={index}
							onClick={() => navigate('/articles')}
							date={DateTime.fromISO(article.dateUpdated)
								.setLocale(getAppLang())
								.toFormat('dd.LL.yyyy')}
							imageUrl={article.image}
							content={article.overviewText}
						/>
					))}
				</div>
			</div>
		</section>
	)
}

export default ArticleSection
