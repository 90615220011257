import { useTranslation } from 'react-i18next'

import { Step1, Step2, Step3 } from 'assets/icons'
import { useNavigate } from 'react-router-dom'
import { getTKey } from 'utils/language'

const AppointmentSteps = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('home')

	return (
		<section className="py-16 bg-[#e9f1f8] relative">
			<div className="text-center mb-12">
				<h1 className="text-[32px] md:text-[40px] font-bold text-[#2c2d6a] leading-tight">
					{t(tKey('headings.bookAppointment'))}
				</h1>
				<p
					dangerouslySetInnerHTML={{ __html: t(tKey('labels.uniqueArticles')) }}
					className="text-md md:text-md text-gray-600 mt-4"
				/>
			</div>

			<div className="relative z-10 flex flex-col items-center space-y-16 md:flex-row md:space-y-0 md:space-x-32 justify-center">
				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step1} alt="Step 1" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">
						{t(tKey('appointmentSteps.headings.customFind'))}
					</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-md md:text-md">
						{t(tKey('appointmentSteps.labels.customFind'))}
					</p>
				</div>

				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step2} alt="Step 2" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">
						{t(tKey('appointmentSteps.headings.searchBusiness'))}
					</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-md md:text-md">
						{t(tKey('appointmentSteps.labels.searchBusiness'))}
					</p>
				</div>

				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step3} alt="Step 3" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">
						{t(tKey('appointmentSteps.headings.bookAppointment'))}
					</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-md md:text-md">
						{t(tKey('appointmentSteps.labels.bookAppointment'))}
					</p>
				</div>
			</div>

			<div className="flex justify-center mt-12 relative z-10">
				<button
					onClick={() => navigate('/search-results')}
					className="bg-[#5964D3] text-white px-6 py-2 md:px-8 md:py-3 rounded-full hover:bg-[#7D8AE6] focus:outline-none">
					{t(tKey('buttons.bookNow'))}
				</button>
			</div>
		</section>
	)
}

export default AppointmentSteps
