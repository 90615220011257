import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight, FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { DateTime } from 'luxon'

import { getTKey } from 'utils/language'

interface ProviderCardProps {
	id: string
	imageUrl: string
	title: string
	description: string
	category: string
	created: number
	rating: number
	totalReviews: number
	zipCode: string
	city: string
}

const ProviderCard = ({
	id,
	imageUrl,
	title,
	description,
	zipCode,
	city,
	created,
	totalReviews,
	category,
	rating
}: ProviderCardProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('home')

	const isRecentCompany = DateTime.fromMillis(created).diffNow('months').months >= -1

	return (
		<div className="w-full mx-auto">
			<div className="relative overflow-hidden">
				<img src={imageUrl} alt={title} className="w-full h-40 md:h-40 rounded-md object-cover" />
				{isRecentCompany && (
					<div className="absolute top-0 right-0 w-[120px] h-[30px] bg-red-500 rotate-[45deg] translate-x-8 md:translate-x-8 translate-y-2 md:translate-y-3 flex items-center justify-center">
						<span className="text-white font-bold text-sm">{t(tKey('labels.new'))}</span>
					</div>
				)}
			</div>
			<div className="p-4">
				<h2 className="text-lg md:text-xl font-semibold text-gray-800">{title}</h2>
				<p className="text-sm text-primary">
					{zipCode} {city}
				</p>
				<p
					dangerouslySetInnerHTML={{ __html: description }}
					className="text-xs line-clamp-2 md:text-sm text-gray-500 mt-1"
				/>
				<div className="flex items-center justify-between mt-4">
					<div className="flex flex-col space-y-2">
						<span className="bg-[#fef1f7] text-purple-600 border border-purple-600 text-xs px-2 py-1 rounded-full">
							{category}
						</span>
						<div className="flex items-center">
							{Array.from({ length: 5 }, (_, index) => {
								const wholeStars = Math.floor(rating)
								const isHalfStar = index === wholeStars && rating % 1 >= 0.3 && rating % 1 < 0.8
								return (
									<React.Fragment key={index}>
										{index < wholeStars ? (
											<FaStar className="size-4 shrink-0 text-[#FFB400]" />
										) : isHalfStar ? (
											<FaStarHalfAlt className="size-4 shrink-0 text-[#FFB400]" />
										) : (
											<FaRegStar className="size-4 shrink-0 text-[#FFB400]" />
										)}
									</React.Fragment>
								)
							})}
							<p className="ml-1 text-sm text-primary font-bold">
								{Number(rating).toFixed(1)} ({totalReviews})
							</p>
						</div>
					</div>

					<button
						onClick={() => navigate(`/company-details/${id}`)}
						className="bg-[#5964D3] text-white p-2 md:p-3 rounded-full hover:bg-[#7D8AE6] focus:outline-none">
						<FaArrowRight />
					</button>
				</div>
			</div>
		</div>
	)
}

export default ProviderCard
