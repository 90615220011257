import {
	ChevronLeftIcon,
	MagnifyingGlassIcon,
	PaperAirplaneIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { NoInboxIcon, NoMessagesIcon } from 'assets/icons'
import clsx from 'clsx'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import {
	addDoc,
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where
} from 'firebase/firestore'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import companyService from 'services/company-service'
import userService from 'services/user-service'
import { getChatDuration } from 'utils/date'
import { getTKey } from 'utils/language'
import { db } from '../../firebase/config'

export const Chats = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams] = useSearchParams()

	const auth = useAppSelector(state => state.auth)
	const user = useAppSelector(state => state.user)

	const tKey = getTKey('chats')

	const chatMessagesRef = useRef<HTMLDivElement>(null)

	const [employees, setEmployees] = useState<Employee[]>([])
	const [searchText, setSearchText] = useState<string>()
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [chatCompanies, setChatCompanies] = useState<ChatCompany[]>([])
	const [chats, setChats] = useState<Chat[]>([])
	const [isChatLoaded, setIsChatLoaded] = useState(false)
	const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
	const [newMessage, setNewMessage] = useState('')
	const [selectedCompany, setSelectedCompany] = useState<ChatCompany>()
	const [selectedUser, setSelectedUser] = useState<User>()
	const [selectedChat, setSelectedChat] = useState<Chat>()
	const [companyLogos, setCompanyLogos] = useState<{
		[id: string]: { name: string; url: string }
	}>({})

	useEffect(() => {
		if (chatMessagesRef.current) {
			const { current } = chatMessagesRef
			current.scrollTop = current.scrollHeight
		}
	}, [chatMessages, chatMessagesRef, selectedChat])

	useEffect(() => {
		userService.getAllCompanyEmployees().then(res => setEmployees(res))
	}, [])

	useEffect(() => {
		const q1 = query(collection(db, 'chats'), where('participantOne.participantId', '==', auth.id))
		const q2 = query(collection(db, 'chats'), where('participantTwo.participantId', '==', auth.id))

		const unsubscribe1 = onSnapshot(q1, querySnapshot => {
			const chatsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Chat)

			updateChatsAndFetchLogos(chatsData)
		})

		const unsubscribe2 = onSnapshot(q2, querySnapshot => {
			const chatsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Chat)

			updateChatsAndFetchLogos(chatsData)
		})

		const updateChatsAndFetchLogos = (newChats: Chat[]) => {
			setChats(prevChats => {
				const updatedChats = [...prevChats]
				newChats.forEach(newChat => {
					const existingIndex = updatedChats.findIndex(chat => chat.id === newChat.id)
					if (existingIndex >= 0) {
						updatedChats[existingIndex] = newChat
					} else {
						updatedChats.push(newChat)
					}
				})
				return updatedChats
			})

			const uniqueCompanies = Array.from(
				new Set(
					newChats.map(chat =>
						JSON.stringify({ companyId: chat.companyId, companyName: chat.companyName })
					)
				)
			).map(companyObj => JSON.parse(companyObj))

			const newCompaniesToFetch = uniqueCompanies.filter(
				company => !companyLogos[company.companyId]
			)

			if (newCompaniesToFetch.length > 0) {
				Promise.all(
					newCompaniesToFetch.map(async company => {
						const response = await companyService.getCompanyLogo(company.companyId)
						return { [company.companyId]: response[0] }
					})
				).then(response => {
					setCompanyLogos(prevCompanyLogos => ({
						...prevCompanyLogos,
						...response.reduce((acc, curr) => ({ ...acc, ...curr }), {})
					}))

					setIsChatLoaded(true)
				})
			} else {
				setIsChatLoaded(true)
			}

			setChatCompanies(prevCompanies => {
				const combinedCompanies = [...prevCompanies, ...uniqueCompanies]
				return Array.from(new Set(combinedCompanies.map(company => JSON.stringify(company)))).map(
					company => JSON.parse(company)
				)
			})

			setSelectedCompany(prevCompany => prevCompany || uniqueCompanies[0])
		}

		return () => {
			unsubscribe1()
			unsubscribe2()
		}
	}, [auth, companyLogos])

	const getChatMessages = async (chat: Chat) => {
		if (!chat.id) {
			console.error('Chat ID is undefined. Cannot fetch chat messages.')
			return
		}

		setSelectedChat(chat)
		setSelectedUser(
			(employees as unknown as User[])?.find(user =>
				auth.id === chat.participantOne.participantId
					? user._id === chat.participantTwo.participantId
					: user._id === chat.participantOne.participantId
			)
		)

		const q = query(
			collection(db, 'chats', chat.id, 'chatMessages'),
			orderBy('createdTimestamp', 'asc')
		)

		const unsubscribe = onSnapshot(q, querySnapshot => {
			const messagesList = querySnapshot.docs.map(
				doc =>
					({
						id: doc.id,
						...doc.data()
					}) as ChatMessage
			)

			setChatMessages(messagesList)
		})

		return () => {
			unsubscribe()
		}
	}

	const markAsRead = useCallback(
		(chatId: string) => {
			updateDoc(doc(db, 'chats', chatId), {
				[`unreadMessages.${auth.id}`]: false
			})
		},
		[auth.id]
	)

	const sendMessage = async (event: React.FormEvent) => {
		event.preventDefault()

		if (!selectedChat || !selectedChat.id || !newMessage.trim()) return

		const messageToSend = newMessage
		setNewMessage('')

		const messagesCollectionRef = collection(db, 'chats', selectedChat.id, 'chatMessages')
		const newMessageObject: Omit<ChatMessage, 'id'> = {
			chatId: selectedChat.id,
			senderId: auth.id,
			messageText: messageToSend,
			senderModel: 'clients',
			createdTimestamp: serverTimestamp()
		}

		await addDoc(messagesCollectionRef, newMessageObject)

		const receiverId =
			selectedChat.participantOne.participantId === auth.id
				? selectedChat.participantTwo.participantId
				: selectedChat.participantOne.participantId

		await updateDoc(doc(db, 'chats', selectedChat.id), {
			[`unreadMessages.${receiverId}`]: true,
			lastMessage: {
				messageText: messageToSend
			},
			updated: serverTimestamp()
		})
	}

	const { filteredChats } = useMemo(() => {
		const participantList = employees

		const filteredChats = chats
			?.filter(chat => chat.companyId === selectedCompany?.companyId)
			?.reduce((prev, curr) => {
				const participantId =
					curr.participantOne.participantId === auth.id
						? curr.participantTwo.participantId
						: curr.participantOne.participantId

				const participant = participantList.find(p => p._id === participantId)

				if (participant) {
					const fullName = participant.fname + ' ' + participant.lname

					if (searchText && fullName.toLowerCase().includes(searchText.toLowerCase())) {
						return [...prev, curr]
					} else if (!searchText) {
						return [...prev, curr]
					}
				}

				return prev
			}, [] as Chat[])

		return { filteredChats }
	}, [chats, searchText, selectedCompany, employees])

	if (!isChatLoaded) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black font-bold -ml-9 text-xl animate-pulse">
							Please Wait...
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	if (chats.length === 0) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="h-[70vh] flex flex-col gap-y-7 max-lg:px-10 items-center justify-center">
					<img src={NoInboxIcon} />
					<h2
						style={{
							color: 'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
						}}
						className="font-domine font-bold ">
						{t(tKey('noMessagesYet'))}
					</h2>
					<p className="text-[#9E9E9E] text-sm font-semibold text-center">
						{t(tKey('noMessageInbox'))} <br /> {t(tKey('notification'))}
					</p>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout
			renderDashboardHeader
			wrapperClassName="flex h-full"
			className="h-screen flex flex-col items-stretch">
			<div className="flex w-full">
				<div className="lg:basis-[35%] max-lg:-mt-3 basis-full flex">
					<div className="bg-primary-gradient max-lg:hidden h-full fixed overflow-hidden items-center p-6 w-[125px] flex flex-col gap-y-14">
						<div className="pt-6">
							<svg
								width="25"
								height="24"
								viewBox="0 0 25 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="shrink-0">
								<path
									d="M4.80775 20.5C4.30258 20.5 3.875 20.325 3.525 19.975C3.175 19.625 3 19.1974 3 18.6923V8.30775C3 7.80258 3.175 7.375 3.525 7.025C3.875 6.675 4.30258 6.5 4.80775 6.5H9V4.80775C9 4.30258 9.175 3.875 9.525 3.525C9.875 3.175 10.3026 3 10.8078 3H14.1923C14.6974 3 15.125 3.175 15.475 3.525C15.825 3.875 16 4.30258 16 4.80775V6.5H20.1923C20.6974 6.5 21.125 6.675 21.475 7.025C21.825 7.375 22 7.80258 22 8.30775V18.6923C22 19.1974 21.825 19.625 21.475 19.975C21.125 20.325 20.6974 20.5 20.1923 20.5H4.80775ZM4.80775 19H20.1923C20.2693 19 20.3398 18.9679 20.4038 18.9038C20.4679 18.8398 20.5 18.7692 20.5 18.6923V8.30775C20.5 8.23075 20.4679 8.16025 20.4038 8.09625C20.3398 8.03208 20.2693 8 20.1923 8H4.80775C4.73075 8 4.66025 8.03208 4.59625 8.09625C4.53208 8.16025 4.5 8.23075 4.5 8.30775V18.6923C4.5 18.7692 4.53208 18.8398 4.59625 18.9038C4.66025 18.9679 4.73075 19 4.80775 19ZM10.5 6.5H14.5V4.80775C14.5 4.73075 14.4679 4.66025 14.4038 4.59625C14.3398 4.53208 14.2692 4.5 14.1923 4.5H10.8078C10.7308 4.5 10.6603 4.53208 10.5963 4.59625C10.5321 4.66025 10.5 4.73075 10.5 4.80775V6.5Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className="flex min-w-max flex-col gap-y-6">
							{chatCompanies.map(company => (
								<div
									onClick={() => setSelectedCompany(company)}
									key={company.companyId}
									className="relative cursor-pointer flex items-center justify-center">
									<img
										src={companyLogos[company.companyId]?.url}
										className="h-[48px] border-[#FFFFFF] z-10 rounded-full object-cover border-[0.5px] w-[48px]"
									/>
									{company === selectedCompany && (
										<div className="absolute bg-[#F5F7FA] h-[150%] w-[1000%] translate-x-[200px] rounded-l-xl" />
									)}
								</div>
							))}
						</div>
					</div>
					<div className="lg:bg-[#F5F7FA] lg:ml-[125px] border-r border-[#CFD8DC80] p-6 basis-[100%] flex flex-col gap-y-6 lg:gap-y-8">
						<div className="lg:bg-primary-light flex max-lg:justify-between items-center lg:h-16 lg:border-b lg:border-[#CFD8DC80] lg:p-6 rounded-lg">
							<div className="flex items-center gap-5">
								{user.profile_url === 'crm' ? (
									<div className="max-lg:hidden rounded-full h-8 w-8 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
										<p
											style={{
												color:
													'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
											}}>
											{user.fname.slice(0, 1)}
										</p>
									</div>
								) : (
									<img
										src={user.profile_url}
										className="h-10 max-lg:hidden w-10 border-[0.5px] border-[#FFFFFF] rounded-full object-cover"
									/>
								)}
								<h5 className="font-domine font-bold lg:text-white">Chats</h5>
							</div>

							<div
								onClick={() => {
									if (isSearchVisible) {
										setSearchText('')
										return setIsSearchVisible(false)
									}
									setIsSearchVisible(true)
								}}
								className="border border-[#D3E3F1] cursor-pointer rounded-full lg:hidden flex items-center justify-center p-1.5"
								style={{ boxShadow: '0px 6px 24px 0px #12325814' }}>
								<MagnifyingGlassIcon className="h-4 w-4 shrink-0" />
							</div>
						</div>

						{isSearchVisible && (
							<div className="relative lg:hidden flex items-center">
								<input
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									type="text"
									placeholder={t(tKey('placeholders.keyword'))}
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
									className="px-4 py-2 w-full placeholder:text-[#7F9AB2] text-primary focus:border-primary border border-[#CFD8DC] rounded-md"
								/>
								<XMarkIcon
									onClick={() => {
										setSearchText('')
										setIsSearchVisible(false)
									}}
									className="absolute h-6 w-6 right-3 stroke-primary stroke-1"
								/>
							</div>
						)}

						<div className="flex flex-col gap-y-6">
							<div className="flex flex-col gap-y-3 lg:gap-y-6">
								<div className="flex lg:hidden items-center gap-2.5 flex-wrap">
									{chatCompanies.map(company => (
										<div
											key={company.companyId}
											onClick={() => setSelectedCompany(company)}
											className="flex cursor-pointer items-center justify-center">
											<img
												src={companyLogos[company.companyId]?.url}
												className={clsx(
													'h-[40px] z-10 rounded-full object-cover w-[40px]',
													company === selectedCompany
														? 'border-[3.33px] border-[#5964D34D]'
														: 'border-[#FFFFFF] border-[0.5px]'
												)}
											/>
										</div>
									))}
								</div>
								<h1 className="font-domine text-sm text-[#252041] font-bold">
									{selectedCompany?.companyName}
								</h1>
							</div>
							<div className="relative max-lg:hidden flex items-center justify-end">
								<input
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									type="text"
									placeholder={t(tKey('placeholders.keyword'))}
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
									className="px-4 py-2 w-full placeholder:text-[#7F9AB2] text-primary focus:border-primary border border-[#CFD8DC] rounded-md"
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									className="absolute -translate-x-3">
									<g clipPath="url(#clip0_2878_8260)">
										<path
											d="M15.4996 14.5006H14.7096L14.4296 14.2306C15.6296 12.8306 16.2496 10.9206 15.9096 8.89063C15.4396 6.11063 13.1196 3.89063 10.3196 3.55063C6.08965 3.03063 2.52965 6.59063 3.04965 10.8206C3.38965 13.6206 5.60965 15.9406 8.38965 16.4106C10.4196 16.7506 12.3296 16.1306 13.7296 14.9306L13.9996 15.2106V16.0006L18.2496 20.2506C18.6596 20.6606 19.3296 20.6606 19.7396 20.2506C20.1496 19.8406 20.1496 19.1706 19.7396 18.7606L15.4996 14.5006ZM9.49965 14.5006C7.00965 14.5006 4.99965 12.4906 4.99965 10.0006C4.99965 7.51063 7.00965 5.50063 9.49965 5.50063C11.9896 5.50063 13.9996 7.51063 13.9996 10.0006C13.9996 12.4906 11.9896 14.5006 9.49965 14.5006Z"
											fill="#123258"
										/>
									</g>
									<defs>
										<clipPath id="clip0_2878_8260">
											<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
										</clipPath>
									</defs>
								</svg>
							</div>
						</div>

						<div className="flex flex-col gap-y-2.5">
							{filteredChats.map(chat => {
								const receiver = (employees as unknown as User[])?.find(user =>
									auth.id === chat.participantOne.participantId
										? user._id === chat.participantTwo.participantId
										: user._id === chat.participantOne.participantId
								)

								return (
									<div
										onClick={() => {
											setSearchText('')
											getChatMessages(chat)
											if (chat.unreadMessages[auth.id]) {
												markAsRead(chat.id)
											}
										}}
										key={chat.id}
										className="flex cursor-pointer justify-between items-start">
										<div className="flex gap-x-3 items-start">
											{receiver?.profile_url === 'crm' ? (
												<div className="rounded-full h-8 w-8 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
													<p
														style={{
															color:
																'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
														}}>
														{receiver?.fname.slice(0, 1)}
													</p>
												</div>
											) : (
												<img
													className="h-8 w-8 rounded-full object-cover"
													src={receiver?.profile_url}
												/>
											)}
											<div className="flex flex-col gap-y-1">
												<h3 className="font-domine font-bold text-sm text-[#252041]">
													{receiver?.fname + ' ' + receiver?.lname}
												</h3>
												{chat.lastMessage && (
													<p
														className={clsx('text-[11px] text-[#9E9E9E]', {
															'font-bold !text-gray-600': chat.unreadMessages[auth.id]
														})}>
														{chat.lastMessage.messageText}
													</p>
												)}
											</div>
										</div>
										<div className="flex flex-col items-end gap-y-1">
											<p className="text-[10px] text-[#9E9E9E]">
												{getChatDuration(chat.updated)} {t(tKey('ago'))}
											</p>
											{chat.unreadMessages[auth.id] && (
												<div className="p-0.5 bg-primary-light h-2.5 w-2.5 rounded-full flex items-center justify-center" />
											)}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				{isMobile && selectedChat && (
					<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
						<div className="sticky flex items-center gap-x-6 top-0 z-10 bg-white p-5 pb-4 pt-6 border-b border-border">
							<ChevronLeftIcon
								onClick={() => setSelectedChat(undefined)}
								className="w-3 h-3 md:hidden cursor-pointer"
							/>
							<div className="flex gap-3">
								{selectedUser?.profile_url === 'crm' ? (
									<div className="rounded-full h-10 w-10 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
										<p
											style={{
												color:
													'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
											}}
											className="text-lg">
											{selectedUser?.fname.slice(0, 1)}
										</p>
									</div>
								) : (
									<img src={selectedUser?.profile_url} className="h-10 w-10 rounded-full" />
								)}
								<div className="flex flex-col gap-y-1">
									<h2 className="font-domine font-bold text-sm">
										{selectedUser?.fname + ' ' + selectedUser?.lname}
									</h2>
									<p className="text-xs font-semibold text-[#5A6555]">
										{selectedCompany?.companyName}
									</p>
								</div>
							</div>
						</div>

						<div ref={chatMessagesRef} className="h-[77vh] overflow-auto p-6 flex flex-col gap-y-3">
							{chatMessages && chatMessages?.length > 0 ? (
								chatMessages?.map((message, index) => {
									const isMyMessage = auth.id === message.senderId
									return (
										<div key={index} className="flex flex-col gap-y-[7px]">
											<div
												className={clsx(
													'py-1.5 w-fit px-6 flex justify-center rounded-xl',
													!isMyMessage
														? 'bg-[#CFD8DC80] text-[#323232]'
														: 'bg-primary-light ml-auto text-white'
												)}>
												<p className="font-semibold text-xs ">{message.messageText}</p>
											</div>
											<p
												className={clsx('text-[10px] text-[#9E9E9E]', {
													'ml-auto': isMyMessage
												})}>
												{getChatDuration(message.createdTimestamp)} {t(tKey('ago'))}
											</p>
										</div>
									)
								})
							) : (
								<div className="flex h-full flex-col items-center justify-center gap-y-7">
									<img src={NoMessagesIcon} />
									<div className="flex flex-col items-center gap-y-6">
										<h1 className="font-domine font-bold text-lg">
											{t(tKey('labels.noMessagesYet'))}
										</h1>
										<p className="text-sm text-center text-[#9E9E9E]">
											{t(tKey('labels.noInboxYet'))} <br />
											{t(tKey('labels.startChat'))}
										</p>
									</div>
								</div>
							)}
						</div>

						<form
							onSubmit={sendMessage}
							className="absolute p-6 w-full flex justify-end items-center bottom-0">
							{user.profile_url === 'crm' ? (
								<div className="rounded-full mr-3 shrink-0 h-8 w-8 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
									<p
										style={{
											color:
												'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
										}}>
										{user.fname.slice(0, 1)}
									</p>
								</div>
							) : (
								<img
									src={user.profile_url}
									className="h-10 max-lg:hidden w-10 shrink-0 mr-3 border-[0.5px] border-[#FFFFFF] rounded-full object-cover"
								/>
							)}
							<input
								required
								className="w-full focus:ring-0 focus:outline-none focus:border-primary-light py-4 pl-6 pr-12 border border-[#CFD8DC] rounded-xl bg-[#F5F7FA] text-sm font-semibold text-[#323232]"
								placeholder="Type a message"
								value={newMessage}
								onChange={e => setNewMessage(e.target.value)}
							/>
							<button
								type="submit"
								className="p-1.5 bg-primary-light -translate-x-4 absolute h-[30px] w-[30px] rounded-full flex items-center justify-center">
								<PaperAirplaneIcon className="text-white" />
							</button>
						</form>
					</Modal>
				)}

				{!isMobile && (
					<div
						style={{ boxShadow: '0px 4px 24px 0px #001F3F14' }}
						className="relative grow flex flex-col gap-6 basis-[65%] border border-[#CFD8DC80]">
						{selectedChat && (
							<div className="w-full h-full relative">
								<div className="absolute bg-white w-full top-0 p-6 flex items-center">
									<div className="flex gap-3 rounded-lg h-16 items-center bg-primary-light border-b border-[#CFD8DC80] p-6 w-full">
										{selectedUser?.profile_url === 'crm' ? (
											<div className="rounded-full h-10 w-10 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
												<p
													style={{
														color:
															'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
													}}
													className="text-lg">
													{selectedUser?.fname.slice(0, 1)}
												</p>
											</div>
										) : (
											<img src={selectedUser?.profile_url} className="h-10 w-10 rounded-full" />
										)}
										<div className="flex flex-col gap-y-1">
											<h2 className="font-domine text-white font-bold text-sm">
												{selectedUser?.fname + ' ' + selectedUser?.lname}
											</h2>

											<p className="text-[11px] text-[#5964D3]">{t(tKey('labels.staff'))}</p>
										</div>
									</div>
								</div>
								<div
									ref={chatMessagesRef}
									className="px-[46px] h-[63vh] overflow-auto mt-24 py-6 flex flex-col gap-y-3">
									{chatMessages &&
										chatMessages.length > 0 &&
										chatMessages?.map((message, index) => {
											const isMyMessage = auth.id === message.senderId
											return (
												<div key={index} className="flex flex-col gap-y-[7px]">
													<div
														className={clsx(
															'py-1.5 w-fit px-6 flex justify-center rounded-xl',
															!isMyMessage
																? 'bg-[#CFD8DC80] text-[#323232]'
																: 'bg-primary-light ml-auto text-white'
														)}>
														<p className="font-semibold text-xs ">{message.messageText}</p>
													</div>
													<p
														className={clsx('text-[10px] text-[#9E9E9E]', {
															'ml-auto': isMyMessage
														})}>
														{getChatDuration(message.createdTimestamp)} {t(tKey('ago'))}
													</p>
												</div>
											)
										})}
									{chatMessages && chatMessages.length === 0 && (
										<div className="flex h-full flex-col items-center justify-center gap-y-7">
											<img src={NoMessagesIcon} />
											<div className="flex flex-col items-center gap-y-6">
												<h1 className="font-domine font-bold text-lg">
													{t(tKey('labels.noMessagesYet'))}
												</h1>
												<p className="text-sm text-center text-[#9E9E9E]">
													{t(tKey('labels.noInboxYet'))} <br />
													{t(tKey('labels.startChat'))}
												</p>
											</div>
										</div>
									)}
								</div>

								<form
									onSubmit={sendMessage}
									className="p-6 w-full absolute bottom-0 flex justify-end items-center">
									<input
										required
										className="w-full focus:ring-0 focus:outline-none focus:border-primary-light py-4 pl-6 pr-12 border border-[#CFD8DC] rounded-xl bg-[#F5F7FA] text-sm font-semibold text-[#323232]"
										placeholder="Type a message"
										value={newMessage}
										onChange={e => setNewMessage(e.target.value)}
									/>
									<button
										type="submit"
										className="p-1.5 bg-primary-light -translate-x-4 absolute h-[30px] w-[30px] rounded-full flex items-center justify-center">
										<PaperAirplaneIcon className="text-white" />
									</button>
								</form>
							</div>
						)}

						{chats && chats.length === 0 && (
							<div className="flex flex-col h-full w-full justify-center items-center gap-y-6">
								<img src={NoInboxIcon} />
								<h2
									style={{
										color:
											'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
									}}
									className="font-domine font-bold">
									{t(tKey('titles.noMessagesYet'))}
								</h2>
								<p
									dangerouslySetInnerHTML={{ __html: t(tKey('labels.noInboxMessage')) }}
									className="text-[#9E9E9E] text-center text-sm font-semibold"
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</AppLayout>
	)
}
