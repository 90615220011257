import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { getAppLang } from 'utils/language'

interface DateCardProps {
	date: number
	variant?: 'appointment'
	className?: string
}

export const DateCard = ({ date, className, variant }: DateCardProps) => {
	const { t } = useTranslation()
	const appLanguage = getAppLang()

	return (
		<div
			className={clsx(
				'bg-[#222B5C] relative rounded-[14px] flex flex-col items-center justify-center shrink-0 text-white',
				variant === 'appointment' ? 'gap-y-1 w-[100px] h-[100px]' : 'gap-y-1 w-[120px] h-[120px]',
				className
			)}>
			<span
				className={clsx(
					'font-semibold z-10',
					variant === 'appointment' ? 'text-[10px]' : 'text-xs'
				)}>
				{DateTime.fromMillis(date).setLocale(appLanguage).toFormat('MMMM')}
			</span>
			<span
				className={clsx(
					'font-domine font-bold z-10',
					variant === 'appointment' ? 'text-[32px]' : 'text-[40px]'
				)}>
				{DateTime.fromMillis(date).toFormat('d')}
			</span>
			<div
				className={clsx(
					'flex gap-x-1 font-semibold z-10',
					variant === 'appointment' ? 'text-[10px]' : 'text-xs'
				)}>
				<span>{t('userDashboard.appointments.at')}</span>
				<span>{DateTime.fromMillis(date).toFormat('HH:mm')}</span>
			</div>
			{variant === 'appointment' ? (
				<>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="100"
						height="43"
						viewBox="0 0 100 43"
						className="absolute bottom-0 right-0"
						fill="none">
						<circle opacity="0.45" cx="118" cy="118.291" r="118" fill="white" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="75"
						height="75"
						viewBox="0 0 75 75"
						className="absolute top-0 right-0"
						fill="none">
						<circle opacity="0.45" cx="118" cy="-29.7092" r="118" fill="white" />
					</svg>
				</>
			) : (
				<>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="146"
						height="98"
						viewBox="0 0 146 98"
						className="absolute bottom-0 right-0"
						fill="none">
						<circle opacity="0.45" cx="118" cy="118.291" r="118" fill="white" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="106"
						height="88"
						viewBox="0 0 106 88"
						className="absolute top-0 right-0"
						fill="none">
						<circle opacity="0.45" cx="118" cy="-29.7092" r="118" fill="white" />
					</svg>
				</>
			)}
		</div>
	)
}
