import { LockClosedIcon, XMarkIcon } from '@heroicons/react/20/solid'
import * as Slider from '@radix-ui/react-slider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as CalendarIcon } from 'assets/svgs/coupon-calendar.svg'
import { ReactComponent as CurrencyIcon } from 'assets/svgs/currency.svg'
import { ReactComponent as GemIcon } from 'assets/svgs/diamond.svg'
import { ReactComponent as RibbonIcon } from 'assets/svgs/ribbon.svg'
import { ReactComponent as TrophyIcon } from 'assets/svgs/trophy.svg'
import { Spinner } from 'components/animations/spinner'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { Modal } from 'components/app/modal'
import { Pagination } from 'components/app/pagination'
import { TanstackTable } from 'components/app/table'
import couponService from 'services/coupon-service'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'

export const Coupons = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const tKey = getTKey('coupons')

	const [currentPoints, setCurrentPoints] = useState<number>(0)
	const [isLoading, setIsLoading] = useState(true)
	const [openPointsModal, setOpenPointsModal] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)
	const [coupons, setCoupons] = useState<Coupon[]>([])
	const percentage = (currentPoints / 2000) * 100 > 100 ? 100 : (currentPoints / 2000) * 100

	const isAlmostThere =
		(currentPoints >= 900 && currentPoints < 1000) ||
		(currentPoints >= 1400 && currentPoints < 1500) ||
		(currentPoints >= 1900 && currentPoints < 2000)

	useEffect(() => {
		userService.getUserInfo().then(res => setCurrentPoints(res.points_balance))
		couponService
			.getCoupons()
			.then(res => setCoupons(res))
			.finally(() => setIsLoading(false))
	}, [forceRefresh])

	const discountByPoints = { 1000: 10, 1500: 15, 2000: 20 } as { [point: number]: number }

	const createCoupon = (points: number) => {
		if (points > currentPoints) {
			toast.error(t(tKey('toasts.enoughPoints')))
		} else {
			couponService
				.createCoupon({ pointsCost: points, discountAmount: discountByPoints[points] })
				.then(() => {
					setForceRefresh(prev => !prev)
					setCurrentPoints(prev => prev - points)
					toast.success(t(tKey('toasts.couponCreationSuccess')))
				})
				.catch(err =>
					toast.error(err?.response?.data?.message ?? t(tKey('toasts.couponCreationError')))
				)
		}
	}

	const columns = [
		{
			header: t(tKey('tables.couponId')),
			accessorKey: 'id'
		},
		{
			header: t(tKey('tables.code')),
			accessorKey: 'code'
		},
		{
			header: t(tKey('tables.discount')),
			accessorKey: 'discount_amount'
		},
		{
			header: t(tKey('tables.redeemedPoints')),
			accessorKey: 'points_cost'
		},
		{
			header: t(tKey('tables.createdDate')),
			accessorKey: 'created_at',
			cell: (date: any) =>
				DateTime.fromISO(date.getValue()).setLocale(getAppLang()).toFormat('dd.LL.yyyy')
		},
		{
			header: t(tKey('tables.status')),
			accessorKey: 'is_redeemed',
			cell: (status: any) => (
				<span
					className={clsx(
						'px-5 py-1 flex justify-center w-fit rounded-[20px] text-center whitespace-nowrap text-white font-semibold text-sm',
						{
							'bg-[#7F9AB2]': !status.getValue(),
							'bg-[#B790D8]': status.getValue()
						}
					)}>
					{status.getValue() ? t(tKey('labels.used')) : t(tKey('labels.available'))}
				</span>
			)
		}
	]

	const renderMobileCouponTable = (coupons: Coupon[]) => {
		return (
			<>
				{coupons.map((coupon, index) => (
					<div key={coupon._id} className="w-full bg-white">
						<Disclosure>
							{({ open }) => (
								<>
									<Disclosure.Button
										className={clsx(
											'flex justify-between w-full px-3 py-2 border border-[#1C1C1C1A]',
											{
												'bg-background': open,
												'border-t-0': index > 0
											}
										)}>
										<div className="flex w-full items-center justify-between">
											<div className="flex gap-x-2.5 items-center">
												<ChevronRightIcon
													className={clsx('h-4 w-4 text-primary shrink-0', {
														'rotate-90 transform': open
													})}
												/>
												<TrophyIcon className="size-5" />

												<div className="flex items-start flex-col gap-y-1">
													<h3 className="text-primary text-sm font-semibold">{coupon.code}</h3>
													<div className="flex gap-x-2 items-center">
														<p className="text-tertiary text-xs">{coupon.discount_amount}</p>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="4"
															height="4"
															viewBox="0 0 4 4"
															fill="none"
															className="shrink-0">
															<circle cx="2" cy="2" r="2" fill="#D3E3F1" />
														</svg>
														<p
															className={clsx('text-xs whitespace-nowrap', {
																'text-[#7F9AB2]': !coupon.is_redeemed,
																'text-[#B790D8]': coupon.is_redeemed
															})}>
															{coupon.is_redeemed
																? t(tKey('labels.used'))
																: t(tKey('labels.available'))}
														</p>
													</div>
												</div>
											</div>
										</div>
									</Disclosure.Button>
									<Disclosure.Panel>
										<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
											<h5 className="text-primary font-semibold text-sm">
												{t(tKey('tables.couponId'))}:
											</h5>
											<p className="text-primary text-sm">{index + 1}</p>
										</div>
										<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
											<h5 className="text-primary font-semibold text-sm">
												{t(tKey('tables.createdDate'))}:
											</h5>
											<p className="text-primary text-sm">
												{DateTime.fromISO(coupon.created_at).toFormat('dd.LL.yy')}
											</p>
										</div>
										<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
											<h5 className="text-primary font-semibold text-sm">
												{t(tKey('tables.redeemedPoints'))}:
											</h5>
											<p className="text-primary text-sm">{coupon.points_cost}</p>
										</div>
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					</div>
				))}
			</>
		)
	}

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-1/2 -translate-y-1/2 h-full w-full left-1/2 -translate-x-1/2 bg-black bg-opacity-70 z-50">
					<div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							Please Wait...
						</span>
					</div>
				</div>
				<MobileNavigation />
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			{openPointsModal && (
				<Modal
					width="!w-full"
					isFullHeight
					className="p-4 flex flex-col gap-4"
					noPadding
					showCrossIcon={false}>
					<div className="flex items-center justify-between">
						<h1 className="font-domine font-bold">{t(tKey('headings.howToEarnPoints'))}</h1>
						<XMarkIcon
							onClick={() => setOpenPointsModal(false)}
							className="size-6 cursor-pointer text-primary"
						/>
					</div>
					<div className="flex flex-col gap-3">
						<div
							onClick={() => navigate('/search-results')}
							className="rounded-[22px] cursor-pointer bg-[#E9F1F8] p-3 flex flex-col gap-2">
							<CalendarIcon className="size-6" />
							<div className="flex flex-col gap-1">
								<h3 className="text-primary font-bold font-domine text-xs">
									{t(tKey('labels.bookAppointment'))}
								</h3>
								<p
									className="[&>span]:font-semibold text-xs [&>span]:text-primary-light"
									dangerouslySetInnerHTML={{ __html: t(tKey('labels.bookSpot')) }}
								/>
							</div>
						</div>

						<div className="rounded-[22px] bg-[#E9F1F8] p-3 flex flex-col gap-2">
							<CurrencyIcon className="size-6" />
							<div className="flex flex-col gap-1">
								<h3 className="text-primary font-bold font-domine text-xs">
									{t(tKey('labels.collectPoints'))}
								</h3>
								<div className="flex flex-col gap-1 items-center">
									<div className="flex items-center gap-2">
										<div className="flex text-primary items-center font-bold gap-[22px]">
											<p>1 CHF</p> <p>=</p> <p>1 {t(tKey('labels.points'))}</p>
										</div>
										<p>({t(tKey('labels.onsitePayment'))})</p>
									</div>

									<div className="flex items-center gap-2">
										<div className="flex text-primary font-bold items-center gap-[22px]">
											<p>1 CHF</p> <p>=</p> <p>3 {t(tKey('labels.points'))}</p>
										</div>
										<p>({t(tKey('labels.onlinePayment'))})</p>
									</div>
								</div>
							</div>
						</div>

						<div className="rounded-[22px] bg-[#E9F1F8] p-3 flex flex-col gap-2">
							<RibbonIcon className="size-6" />
							<div className="flex flex-col gap-1">
								<h3 className="text-primary font-bold font-domine text-xs">
									{t(tKey('labels.discountCoupons'))}
								</h3>
								<p
									className="[&>span]:font-semibold text-xs [&>span]:text-primary-light"
									dangerouslySetInnerHTML={{ __html: t(tKey('labels.exchangePoints')) }}
								/>
							</div>
						</div>
					</div>
				</Modal>
			)}
			<div className="lg:px-[60px] px-5 pt-5 bg-[#f0eff3] flex flex-col gap-[26px] min-h-screen max-lg:pb-5 lg:min-h-screen">
				<div className="flex max-lg:flex-col items-center gap-6">
					<div
						style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
						className="py-4 lg:py-[19px] self-stretch max-lg:w-full basis-full lg:basis-1/2 px-4 lg:px-[27px] rounded-[20px] flex flex-col gap-4 lg:gap-[27px] border border-[#DBE5FA] bg-white">
						<h1 className="text-primary font-domine font-bold text-base lg:text-xl">
							{t(tKey('headings.overview'))}
						</h1>
						{/* <div className="grid grid-cols-2 items-center gap-4">
							<div className="bg-[#E9F1F8] gap-6 flex items-center justify-center px-6 py-3">
								<h3 className="text-primary font-semibold text-sm">
									{t(tKey('labels.unlockedCoupons'))}
								</h3>
								<div className="min-w-8 h-8 px-1.5 text-white flex items-center justify-center rounded-md bg-primary-light font-bold italic text-xs">
									{coupons.length}
								</div>
							</div>
							<div className="bg-[#E9F1F8] gap-6 flex items-center justify-center px-6 py-3">
								<h3 className="text-primary font-semibold text-sm">
									{t(tKey('labels.totalEarnedPoints'))}
								</h3>
								<div className="min-w-8 h-8 px-1.5 text-white flex items-center justify-center rounded-md bg-primary-light font-bold italic text-xs">
									{currentPoints}
								</div>
							</div>
						</div> */}
						<div className="bg-gradient-to-r flex items-start gap-2 lg:gap-3.5 px-1.5 lg:px-[26px] py-[9px] lg:py-[13px] min-h-[87px] lg:min-h-[110px] rounded-xl from-[#5166D6] to-[#1A2146] backdrop-blur-sm">
							<div className="flex flex-col w-full gap-2">
								<h2 className="text-white font-domine max-lg:text-sm font-bold lg:mb-1">
									{isAlmostThere ? t(tKey('labels.almostThere')) : t(tKey('labels.yourPoints'))}
								</h2>
								<Slider.Root
									className="relative lg:ml-3 lg:w-[97%] flex shadow-2xl shadow-[#FFB300] items-center h-[5px] bg-white rounded-full"
									value={[currentPoints]}
									onValueChange={value => setCurrentPoints(value[0])}
									max={2000}>
									<Slider.Track className="relative w-full h-1 lg:h-[5px] rounded-full">
										<Slider.Range className="absolute h-full bg-gradient-to-r from-[#FFB300] to-[#ED370E] rounded-md" />
									</Slider.Track>

									{currentPoints > 200 && (
										<CurrencyIcon className="absolute max-lg:size-[23px] left-0 top-3 lg:top-4 text-lg" />
									)}
									<GemIcon
										style={{ left: `${percentage}%` }}
										className="absolute top-3 lg:top-4 max-lg:size-[21px] -translate-x-1/2 text-[#EE3B0E] text-lg"
									/>
									{(currentPoints < 1400 || currentPoints > 1600) && (
										<div
											className={clsx(
												'left-3/4 absolute -translate-x-1/2 text-white text-xs -top-5 rounded-full'
											)}>
											1500
										</div>
									)}
									<div
										className={clsx(
											'left-3/4 absolute size-1 lg:size-[5px] rounded-full',
											currentPoints > 1500 ? 'bg-white' : 'bg-[#ED107A]'
										)}
									/>
									{(currentPoints < 900 || currentPoints > 1100) && (
										<div
											className={clsx(
												'left-1/2 absolute -translate-x-1/2 text-white text-xs -top-5 rounded-full'
											)}>
											1000
										</div>
									)}
									<div
										className={clsx(
											'left-1/2 absolute size-1 lg:size-[5px] rounded-full',
											currentPoints > 1000 ? 'bg-white' : 'bg-[#ED107A]'
										)}
									/>
									{currentPoints > 400 && (
										<div
											className="absolute max-lg:hidden -top-6 text-white font-semibold transform -translate-x-1/2 text-sm"
											style={{ left: `${percentage}%` }}>
											{currentPoints}
										</div>
									)}
								</Slider.Root>
							</div>
							<div className="flex flex-col max-lg:self-center lg:gap-1.5 items-center">
								<h5 className="text-white font-semibold text-[10px] lg:text-xs">
									{t(tKey('labels.points'))}
								</h5>
								<h2 className="lg:text-2xl text-white font-semibold text-center">
									{currentPoints}
								</h2>
							</div>
						</div>
						<h2
							onClick={() => setOpenPointsModal(true)}
							className="text-center lg:hidden cursor-pointer hover:underline text-primary-light font-domine font-bold text-sm">
							{t(tKey('headings.howToEarnPoints'))}
						</h2>
					</div>
					<div
						style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
						className="py-[19px] max-lg:hidden basis-1/2 px-[27px] rounded-[20px] flex flex-col gap-4 border border-[#DBE5FA] bg-white">
						<h1 className="text-primary font-domine font-bold text-xl">
							{t(tKey('headings.howToEarnPoints'))}
						</h1>
						<div className="flex flex-col gap-3">
							<div
								onClick={() => navigate('/search-results')}
								className="bg-[#E9F1F8] cursor-pointer p-3 rounded-full flex items-center gap-2">
								<CalendarIcon />
								<div className="flex flex-col gap-1">
									<h3 className="text-primary font-bold font-domine text-xs">
										{t(tKey('labels.bookAppointment'))}
									</h3>
									<p
										className="[&>span]:font-semibold text-xs [&>span]:text-primary-light"
										dangerouslySetInnerHTML={{ __html: t(tKey('labels.bookSpot')) }}
									/>
								</div>
							</div>
							<div className="bg-[#E9F1F8] p-3 rounded-full flex items-center gap-2">
								<CurrencyIcon className="size-[34px]" />
								<div className="flex items-center justify-between w-full">
									<h3 className="text-primary font-bold font-domine text-xs">
										{t(tKey('labels.collectPoints'))}
									</h3>
									<div className="flex items-center mr-10 justify-center flex-col gap-1">
										<div className="flex items-center gap-4">
											<div className="flex font-domine font-bold text-xl text-primary items-center gap-[22px]">
												<p>1 CHF</p> <p>=</p> <p>1 {t(tKey('labels.points'))}</p>
											</div>
											<p>({t(tKey('labels.onsitePayment'))})</p>
										</div>

										<div className="flex items-center gap-4">
											<div className="flex font-domine font-bold text-xl text-primary items-center gap-[22px]">
												<p>1 CHF</p> <p>=</p> <p>3 {t(tKey('labels.points'))}</p>
											</div>
											<p>({t(tKey('labels.onlinePayment'))})</p>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-[#E9F1F8] p-3 rounded-full flex items-center gap-2">
								<RibbonIcon />
								<div className="flex flex-col gap-1">
									<h3 className="text-primary font-bold font-domine text-xs">
										{t(tKey('labels.discountCoupons'))}
									</h3>
									<p
										className="[&>span]:font-semibold text-xs [&>span]:text-primary-light"
										dangerouslySetInnerHTML={{ __html: t(tKey('labels.exchangePoints')) }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="rounded-[22px] overflow-hidden flex max-lg:flex-col lg:items-center lg:pr-3.5 bg-white">
					<div className="lg:py-[29px] lg:px-5 p-4 bg-[#DDDDFB] self-stretch justify-center flex flex-col gap-1.5 lg:gap-2.5">
						<h1 className="text-primary font-domine font-bold lg:text-xl">
							{t(tKey('headings.createCoupon'))}
						</h1>
						<p className="text-xs text-primary">{t(tKey('labels.redeemPoints'))}</p>
					</div>
					<div className="lg:py-3.5 p-2 lg:px-[34px] max-lg:overflow-auto flex items-center gap-2 lg:gap-5">
						<CreateCouponCard couponPoints={1000} onCouponCreate={createCoupon} />
						<CreateCouponCard couponPoints={1500} onCouponCreate={createCoupon} />
						<CreateCouponCard couponPoints={2000} onCouponCreate={createCoupon} />
					</div>
				</div>

				<div
					style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
					className="lg:hidden rounded-[22px] overflow-hidden border border-[#DBE5FA]">
					<div className="px-6 py-3 flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-[#C6C7F8] text-primary">
						<h3 className="font-domine text-sm font-bold">{t(tKey('headings.couponsHistory'))}</h3>
					</div>
					<Pagination
						className="max-lg:!-mt-3"
						renderComponent={renderMobileCouponTable}
						items={coupons}
					/>
				</div>

				<div className="flex max-lg:hidden flex-col gap-[22px]">
					<h1 className="text-primary font-domine font-bold text-xl">
						{t(tKey('headings.couponsHistory'))}
					</h1>
					<TanstackTable
						data={coupons.map((coupon, index) => ({ id: index + 1, ...coupon }))}
						columns={columns}
					/>
				</div>
			</div>
			<Footer />
		</AppLayout>
	)
}

interface CreateCouponCardProps {
	couponPoints: number
	onCouponCreate: (points: number) => void
}

const CreateCouponCard = ({ couponPoints, onCouponCreate }: CreateCouponCardProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('coupons')

	return (
		<div className="lg:py-3.5 w-[122px] px-3 py-4 lg:px-6 bg-[#EEEEEE99] rounded-xl flex flex-col gap-6">
			<div className="flex flex-col items-center gap-2.5">
				<GemIcon className="text-[#7F9AB2] size-8" />
				<div className="flex flex-col items-center gap-2">
					<h2 className="text-primary text-xl font-semibold text-center">{couponPoints}</h2>
					<p className="text-xs text-primary font-semibold">{t(tKey('labels.points'))}</p>
				</div>
			</div>
			<button
				style={{ boxShadow: '0px 6px 24px 0px #12325814' }}
				onClick={() => onCouponCreate(couponPoints)}
				className="bg-[#7F9AB2] py-1.5 px-3 flex justify-center rounded-full">
				<LockClosedIcon className="size-5 text-white" />
			</button>
		</div>
	)
}
