import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Modal } from 'components/app/modal'
import { FileDropzone } from 'components/document/file-dropzone'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import userService from 'services/user-service'
import { getTKey } from 'utils/language'

interface UploadModalProps {
	onClose: () => void
	onRefresh: () => void
}

export const UploadModal = ({ onRefresh, onClose }: UploadModalProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const tKey = getTKey('documents.uploadModal')

	const user = useAppSelector(state => state.user)

	const [file, setFile] = useState<File>()
	const acceptedFileTypes = [
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'image/png',
		'image/jpeg',
		'image/jpg'
	]

	const handleUpload = (newFile: File) => {
		const fileSizeExceedsLimit = newFile.size > 5242880
		if (!acceptedFileTypes.includes(newFile.type)) return toast.error(t(tKey('validFileToast')))
		if (fileSizeExceedsLimit) {
			toast.error(`${newFile.name} ${t(tKey('sizeExceed'))}`)
		} else {
			setFile(newFile)
		}
	}

	const handleDelete = () => {
		setFile(undefined)
	}

	const handleSubmit = () => {
		if (!file) return toast.error(t(tKey('fileUploadToast')))
		const formdata = new FormData()
		formdata.append('fileUploadType', 'general')
		formdata.append('uploadSource', 'Platform')
		formdata.append('userId', user._id)
		formdata.append('file', file)
		userService
			.uploadDocument(formdata)
			.then(() => {
				toast.success(t(tKey('uploadSuccess')))
				onRefresh()
				onClose()
			})
			.catch(() => toast.error(t(tKey('uploadError'))))
	}

	return (
		<Modal
			isFullHeight
			showCrossIcon={!isMobile}
			crossIconPosition="top-[17px] right-4"
			noPadding
			onClose={onClose}>
			<div className="px-5 mt-4 py-4 lg:py-5 lg:px-6 items-center max-lg:grid max-lg:auto-cols-auto max-lg:grid-flow-col lg:border-b lg:border-[#D3E3F1]">
				<ChevronLeftIcon onClick={onClose} className="h-4 w-4 lg:hidden cursor-pointer" />
				<h2 className="text-primary max-lg:text-sm whitespace-nowrap font-domine font-bold">
					{t(tKey('uploadDocuments'))}
				</h2>
			</div>
			<div className="p-5 lg:px-6 lg:pt-10">
				<FileDropzone onUpload={handleUpload} />
			</div>
			<div className="pt-8 px-6 pb-10">
				{file && (
					<div className="px-6 py-2 rounded bg-[#F5F7FA] hover:bg-gray-100 flex items-center justify-between">
						<div className="flex gap-x-3 items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none">
								<path
									d="M10.5616 14.4923L8.06537 11.9962C7.97179 11.9026 7.85705 11.8526 7.72115 11.8462C7.58525 11.8397 7.4641 11.8897 7.3577 11.9962C7.25128 12.1026 7.19808 12.2205 7.19808 12.35C7.19808 12.4795 7.25128 12.5974 7.3577 12.7038L9.99615 15.3423C10.1577 15.5038 10.3462 15.5846 10.5616 15.5846C10.7769 15.5846 10.9654 15.5038 11.1269 15.3423L16.6039 9.86538C16.6974 9.77179 16.7474 9.65705 16.7538 9.52115C16.7603 9.38525 16.7103 9.2641 16.6039 9.1577C16.4974 9.05128 16.3795 8.99807 16.25 8.99807C16.1205 8.99807 16.0026 9.05128 15.8962 9.1577L10.5616 14.4923ZM12.0034 21C10.7588 21 9.58872 20.7638 8.4931 20.2915C7.39748 19.8192 6.44444 19.1782 5.63397 18.3685C4.82352 17.5588 4.18192 16.6066 3.70915 15.512C3.23638 14.4174 3 13.2479 3 12.0034C3 10.7588 3.23616 9.58872 3.70848 8.4931C4.18081 7.39748 4.82183 6.44444 5.63153 5.63398C6.44123 4.82353 7.39337 4.18192 8.48795 3.70915C9.58255 3.23638 10.7521 3 11.9966 3C13.2412 3 14.4113 3.23616 15.5069 3.70847C16.6025 4.18081 17.5556 4.82182 18.366 5.63152C19.1765 6.44122 19.8181 7.39337 20.2908 8.48795C20.7636 9.58255 21 10.7521 21 11.9966C21 13.2412 20.7638 14.4113 20.2915 15.5069C19.8192 16.6025 19.1782 17.5556 18.3685 18.366C17.5588 19.1765 16.6066 19.8181 15.512 20.2909C14.4174 20.7636 13.2479 21 12.0034 21ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
									fill="#2EEBE2"
								/>
							</svg>
							<h5 className="text-primary text-sm">{file.name}</h5>
						</div>
						<svg
							onClick={handleDelete}
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							className="cursor-pointer"
							fill="none">
							<path
								d="M7.61537 19.9997C7.17114 19.9997 6.79086 19.8415 6.47452 19.5252C6.15817 19.2089 6 18.8286 6 18.3843V5.99972H5.5C5.35833 5.99972 5.23958 5.95174 5.14375 5.85577C5.04792 5.7598 5 5.64089 5 5.49904C5 5.35718 5.04792 5.23849 5.14375 5.14299C5.23958 5.04748 5.35833 4.99972 5.5 4.99972H9C9 4.7933 9.0766 4.61349 9.2298 4.46029C9.38302 4.30708 9.56283 4.23047 9.76923 4.23047H14.2308C14.4372 4.23047 14.617 4.30708 14.7702 4.46029C14.9234 4.61349 15 4.7933 15 4.99972H18.5C18.6417 4.99972 18.7604 5.0477 18.8562 5.14367C18.9521 5.23962 19 5.35853 19 5.50039C19 5.64224 18.9521 5.76093 18.8562 5.85644C18.7604 5.95196 18.6417 5.99972 18.5 5.99972H18V18.3843C18 18.8286 17.8418 19.2089 17.5255 19.5252C17.2091 19.8415 16.8289 19.9997 16.3846 19.9997H7.61537ZM17 5.99972H7V18.3843C7 18.5638 7.05769 18.7113 7.17308 18.8266C7.28846 18.942 7.43589 18.9997 7.61537 18.9997H16.3846C16.5641 18.9997 16.7115 18.942 16.8269 18.8266C16.9423 18.7113 17 18.5638 17 18.3843V5.99972ZM10.3084 16.9997C10.4502 16.9997 10.5689 16.9518 10.6644 16.856C10.7599 16.7601 10.8077 16.6414 10.8077 16.4997V8.49972C10.8077 8.35805 10.7597 8.2393 10.6637 8.14347C10.5678 8.04764 10.4489 7.99972 10.307 7.99972C10.1652 7.99972 10.0465 8.04764 9.95095 8.14347C9.85543 8.2393 9.80768 8.35805 9.80768 8.49972V16.4997C9.80768 16.6414 9.85566 16.7601 9.95163 16.856C10.0476 16.9518 10.1665 16.9997 10.3084 16.9997ZM13.693 16.9997C13.8348 16.9997 13.9535 16.9518 14.049 16.856C14.1446 16.7601 14.1923 16.6414 14.1923 16.4997V8.49972C14.1923 8.35805 14.1443 8.2393 14.0484 8.14347C13.9524 8.04764 13.8335 7.99972 13.6916 7.99972C13.5498 7.99972 13.4311 8.04764 13.3356 8.14347C13.2401 8.2393 13.1923 8.35805 13.1923 8.49972V16.4997C13.1923 16.6414 13.2403 16.7601 13.3363 16.856C13.4322 16.9518 13.5511 16.9997 13.693 16.9997Z"
								fill="#FF5648"
							/>
						</svg>
					</div>
				)}
			</div>
			<div className="px-6 pb-5 flex justify-end gap-x-2.5">
				<button
					onClick={onClose}
					className="lg:border max-lg:font-semibold border-[#9E9E9E] rounded lg:rounded-full text-primary py-[9px] lg:py-1.5 px-4 text-sm">
					{t(tKey('cancel'))}
				</button>
				<button
					onClick={handleSubmit}
					className="border border-primary max-lg:font-semibold text-white rounded lg:rounded-full bg-primary-light py-[9px] lg:py-1.5 px-4 text-sm">
					{t(tKey('upload'))}
				</button>
			</div>
		</Modal>
	)
}
