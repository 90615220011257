import axios from 'services/axios'

const searchCompanies = (
	name?: string,
	location?: string,
	type?: string,
	currentPage?: number,
	topRated?: boolean,
	recommended?: boolean,
	newest?: boolean,
	discounted?: boolean,
	favorites?: boolean,
	userId?: string,
	price?: number
): Promise<{ data: SearchResult[]; meta: SearchResultMeta }> => {
	const queryParams = new URLSearchParams()

	queryParams.append('page', currentPage?.toString() ?? '1')
	queryParams.append('limit', '25')
	queryParams.append('name', name || '')
	queryParams.append('country', location || '')

	if (type) {
		queryParams.append('type', type)
	}
	if (topRated) {
		queryParams.append('top_rated', topRated.toString())
	}
	if (recommended) {
		queryParams.append('recommended', recommended.toString())
	}
	if (newest) {
		queryParams.append('new', newest.toString())
	}
	if (discounted) {
		queryParams.append('discounted', discounted.toString())
	}
	if (favorites) {
		queryParams.append('favorites', favorites.toString())
	}
	if (userId) {
		queryParams.append('userId', userId)
	}
	if (price) {
		queryParams.append('price', price.toString())
	}

	const queryString = queryParams.toString()

	return axios.get(`/company?${queryString}`).then(response => response.data)
}

const getCompanyById = (id: string): Promise<Company> => {
	return axios.get(`/company/${id}`).then(response => response.data)
}

const getSearchSuggestions = (name: string): Promise<SearchSuggestions> => {
	const queryParams = new URLSearchParams()
	queryParams.append('page', '1')
	queryParams.append('limit', '40')
	queryParams.append('name', name || '')

	const queryString = queryParams.toString()

	return axios
		.get(`/searchCompany?${queryString}`)
		.then(response => response.data)
		.catch(err => err)
}

const getCompanyLogo = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/logo/company?company_name=${id}`).then(response => response.data)
}

const getCompanySlider = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/slider/company?company_name=${id}`).then(response => response.data)
}

const getCompanyFaqs = (id: string): Promise<Faq[]> => {
	return axios.get(`/company/faqs/${id}`).then(response => response.data)
}

const getSchedule = (id: string): Promise<Schedule[]> => {
	return axios.get(`/company/scedule/${id}`).then(response => response.data)
}

const companyService = {
	searchCompanies,
	getSearchSuggestions,
	getSchedule,
	getCompanySlider,
	getCompanyById,
	getCompanyFaqs,
	getCompanyLogo
}

export default companyService
