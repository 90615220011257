import { Dispatch, SetStateAction } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { Otp } from 'components/auth/otp'
import { LabelInput } from 'components/inputs/input'
import authService from 'services/auth-service'
import { getTKey } from 'utils/language'

interface ForgotPasswordProps {
	register: UseFormRegister<Auth>
	control: Control<Auth, any>
	errors: FieldErrors<Auth>
	watch: UseFormWatch<Auth>
	setValue: UseFormSetValue<Auth>
	email: string
	pin: string
	setPin: (value: string) => void
	isEmailSent: boolean
	isPhoneVerification: boolean
	showOtp: boolean
	setIsPhoneVerification: Dispatch<SetStateAction<boolean>>
	setIsEmailSent: (value: boolean) => void
	onResendClick: () => void
	isOtpLoading: boolean
	isLoading: boolean
}

export const ForgotPassword = ({
	register,
	errors,
	control,
	email,
	watch,
	pin,
	setPin,
	setValue,
	isEmailSent,
	showOtp,
	setIsEmailSent,
	isPhoneVerification,
	setIsPhoneVerification,
	onResendClick,
	isOtpLoading,
	isLoading
}: ForgotPasswordProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('auth')

	const phoneNumber = watch('phonenumber')

	const sendOtp = () => {
		authService
			.forgotPassword(undefined, phoneNumber)
			.then(() => {
				toast.success(t(tKey('toast.verificationSent')))
			})
			.catch(err => {
				if (err.response && err.response.data) {
					return toast.error(err.response.data.message)
				}
				toast.error(t(tKey('toast.error')))
			})
	}

	if (isEmailSent) {
		return (
			<>
				<div className="flex flex-col gap-y-5">
					<h2 className="font-domine text-[#5964D3] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
						{t(tKey('forgotPassword.labels.thanks'))}
					</h2>
					<p className="text-primary font-bold max-lg:px-5 text-lg lg:text-2xl leading-6 lg:pb-4 max-lg:text-center">
						{t(tKey('forgotPassword.labels.if'))}{' '}
						<span className="font-normal italic">{email}</span>{' '}
						{t(tKey('forgotPassword.labels.message'))}
					</p>
					<p className="text-secondary max-lg:text-center max-lg:px-5">
						{t(tKey('forgotPassword.labels.emailReceived'))}
					</p>
					<div className="text-lg max-lg:text-center">
						<span
							onClick={onResendClick}
							className="text-primary font-semibold underline cursor-pointer">
							{t(tKey('forgotPassword.labels.resend'))}
						</span>{' '}
						/{' '}
						<span
							onClick={() => setIsEmailSent(false)}
							className="text-primary font-semibold underline cursor-pointer">
							{t(tKey('forgotPassword.labels.tryDifferentEmail'))}
						</span>
					</div>
				</div>
				<div className="pt-8">
					<Highlights />
				</div>
			</>
		)
	}

	if (showOtp) {
		return (
			<Otp
				pin={pin}
				onChange={(value: string) => setPin(value)}
				onResendOtp={sendOtp}
				phoneNumber={phoneNumber}
				isLoading={isOtpLoading}
			/>
		)
	}

	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('forgotPassword.title.forgotPassword'))}
			</h2>
			<p className="text-secondary max-lg:px-5 font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{isPhoneVerification
					? t(tKey('forgotPassword.title.forgetPhoneText'))
					: t(tKey('forgotPassword.title.forgetEmailText'))}
			</p>
			<div className="max-lg:px-5">
				{isPhoneVerification ? (
					<Controller
						control={control}
						name={'phonenumber'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder={t(tKey('register.placeholder.labels.enterYourPhone'))}
									defaultCountry="CH"
									value={value ?? '+41'}
									error={error}
									onChange={onChange}
								/>
								{errors?.phonenumber && (
									<p className="text-xs text-red-500 mt-1">
										{errors.phonenumber.message as string}
									</p>
								)}
							</div>
						)}
					/>
				) : (
					<LabelInput
						autoComplete="true"
						register={register}
						name="email"
						labelText={t(tKey('forgotPassword.labels.email'))}
						errors={errors}
					/>
				)}

				<div className="flex justify-center my-6">
					<Button
						className="bg-primary-light whitespace-nowrap text-white w-3/5 py-3 px-0 capitalize text-sm"
						type="submit"
						disabled={isLoading}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('forgotPassword.labels.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{t(tKey('forgotPassword.labels.send'))}</span>
						)}
					</Button>
				</div>
				<div className="flex items-center justify-center gap-x-4 text-sm">
					<p
						onClick={() => {
							setValue('phonenumber', '+41')
							setValue('email', '')
							setIsPhoneVerification(prev => !prev)
						}}
						className="cursor-pointer hover:underline text-primary-light ml-1">
						{isPhoneVerification
							? t(tKey('forgotPassword.labels.verifyUsingEmail'))
							: t(tKey('forgotPassword.labels.verifyUsingPhone'))}
					</p>
				</div>
			</div>
			<div className="max-lg:pt-8">
				<Highlights />
			</div>
		</div>
	)
}
