import { FileInput, Label } from 'flowbite-react'
import { useTranslation } from 'react-i18next'

import { getTKey } from 'utils/language'

interface FileDropzoneProps {
	onUpload: (files: File) => void
}

export const FileDropzone = ({ onUpload }: FileDropzoneProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('documents.uploadModal')

	const handleFileInputChange = (event: any) => {
		const file = event.target.files && event.target.files[0]
		if (file) {
			onUpload(file)
		}
	}

	const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault()
		event.stopPropagation()
		event.currentTarget.classList.add('drag-over-style')
	}

	const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault()
		event.stopPropagation()
		event.currentTarget.classList.remove('drag-over-style')
	}

	const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault()
		event.stopPropagation()
		event.currentTarget.classList.remove('drag-over-style')

		const file = event.dataTransfer.files && event.dataTransfer.files[0]
		if (file) {
			onUpload(file)
		}
	}

	return (
		<div className="flex mx-auto w-full items-center justify-center">
			<Label
				htmlFor="dropzone-file"
				onChange={handleFileInputChange}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				className="dark:hover:bg-bray-800 flex py-8 lg:py-[48px] w-full cursor-pointer flex-col items-center justify-center rounded-xl border border-dashed border-[#12325833] bg-white hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
				<div className="flex flex-col gap-y-4 items-center justify-center pb-6 pt-5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="84"
						height="84"
						viewBox="0 0 84 84"
						fill="none">
						<path
							d="M40.25 40.5327L34.0712 46.6913C33.7548 47.0189 33.3594 47.1827 32.8849 47.1827C32.4103 47.1827 32.0025 47.0122 31.6615 46.6712C31.2891 46.3436 31.1141 45.9308 31.1365 45.4327C31.159 44.9346 31.334 44.5218 31.6615 44.1942L40.0212 35.8346C40.5866 35.2692 41.2462 34.9865 42 34.9865C42.7538 34.9865 43.4134 35.2692 43.9788 35.8346L52.3385 44.1942C52.666 44.5218 52.8444 44.9288 52.8735 45.4154C52.9027 45.9019 52.7243 46.3221 52.3385 46.676C52.0109 47.0003 51.6015 47.1547 51.1101 47.139C50.6187 47.1233 50.2025 46.9516 49.8615 46.624L43.75 40.5327V60.8462C43.75 61.3846 43.9743 61.8782 44.423 62.3269C44.8718 62.7756 45.3654 63 45.9038 63H64.75C67.2 63 69.2708 62.1542 70.9625 60.4625C72.6542 58.7708 73.5 56.7 73.5 54.25C73.5 51.8 72.6542 49.7292 70.9625 48.0375C69.2708 46.3458 67.2 45.5 64.75 45.5H59.5V38.5C59.5 33.6583 57.7938 29.5312 54.3812 26.1187C50.9688 22.7062 46.8417 21 42 21C37.1583 21 33.0312 22.7062 29.6187 26.1187C26.2062 29.5312 24.5 33.6583 24.5 38.5H22.6154C19.3667 38.5 16.5353 39.6958 14.1212 42.0875C11.7071 44.4792 10.5 47.3667 10.5 50.75C10.5 54.1333 11.6958 57.0208 14.0875 59.4125C16.4792 61.8042 19.3667 63 22.75 63H29.75C30.2458 63 30.6615 63.1679 30.9969 63.5038C31.3323 63.8397 31.5 64.2559 31.5 64.7524C31.5 65.2489 31.3323 65.6643 30.9969 65.9985C30.6615 66.3328 30.2458 66.5 29.75 66.5H22.75C18.384 66.5 14.6675 64.9817 11.6005 61.945C8.5335 58.9084 7 55.1969 7 50.8105C7 46.6644 8.42692 43.0702 11.2808 40.0278C14.1346 36.9856 17.4865 35.35 21.3365 35.1211C22.1218 30.0237 24.4664 25.8125 28.3702 22.4875C32.2741 19.1625 36.8173 17.5 42 17.5C47.85 17.5 52.8125 19.5375 56.8875 23.6125C60.9625 27.6875 63 32.65 63 38.5V42H65.1538C68.5058 42.1077 71.3181 43.3383 73.5909 45.6918C75.8636 48.0453 77 50.898 77 54.25C77 57.6827 75.839 60.5825 73.5169 62.9495C71.1948 65.3165 68.3173 66.5 64.8846 66.5H45.9038C44.2929 66.5 42.9479 65.9604 41.8687 64.8812C40.7896 63.8021 40.25 62.4571 40.25 60.8462V40.5327Z"
							fill="#123258"
							fillOpacity="0.6"
						/>
					</svg>
					<p className="mb-2 font-semibold text-secondary">
						<span className="font-semibold text-[#5964D3]">{t(tKey('browse'))}</span>{' '}
						{t(tKey('dragAndDrop'))}
					</p>
				</div>
				<FileInput
					multiple={false}
					id="dropzone-file"
					className="hidden"
					accept="image/*, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				/>
			</Label>
		</div>
	)
}
