import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'

import { getTKey } from 'utils/language'

interface FAQItem {
	question: string
	answer: string
}

export const FAQ = () => {
	const { t } = useTranslation()

	const tKey = getTKey('home')

	const [activeIndex, setActiveIndex] = useState<number | null>(null)

	const toggleFAQ = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index)
	}

	const faqData: FAQItem[] = [
		{
			question: t(tKey('faqs.headings.rescheduleAppointment')),
			answer: t(tKey('faqs.labels.ourPlatform'))
		},
		{
			question: t(tKey('faqs.headings.contactBusiness')),
			answer: t(tKey('faqs.labels.bookingAssist'))
		},
		{
			question: t(tKey('faqs.headings.bookAppointment')),
			answer: t(tKey('faqs.labels.findBusiness'))
		},
		{
			question: t(tKey('faqs.headings.securePayment')),
			answer: t(tKey('faqs.labels.securePayment'))
		}
	]

	return (
		<div className="w-full max-w-4xl mx-auto p-6">
			<h2 className="text-[32px] md:text-[40px] font-bold text-center mb-10">
				{t(tKey('headings.backup'))}
			</h2>
			<div className="space-y-4">
				{faqData.map((faq, index) => (
					<div key={index} className="border-b border-gray-300 pb-4">
						<div
							className="flex justify-between items-center cursor-pointer"
							onClick={() => toggleFAQ(index)}>
							<div className="flex items-center space-x-10 mt-4 mb-4">
								<span className="text-indigo-600 font-bold text-md">{`0${index + 1}`}</span>
								<span
									className={`${activeIndex === index ? 'text-indigo-600' : 'text-black'} text-md font-semibold`}>
									{faq.question}
								</span>
							</div>
							<span>
								{activeIndex === index ? (
									<FaChevronDown className="text-indigo-600" />
								) : (
									<FaChevronRight className="text-gray-400" />
								)}
							</span>
						</div>
						{activeIndex === index && faq.answer && (
							<div className="w-[75%] mb-4 ml-16 text-gray-500 text-md">{faq.answer}</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
