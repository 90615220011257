import clsx from 'clsx'

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				'bg-primary-light border-none rounded text-white text-base capitalize cursor-pointer',
				className?.includes('p') ? className : 'py-3 px-8',
				className,
				{
					'pointer-events-none cursor-not-allowed text-gray-400': props.disabled
				}
			)}>
			{children}
		</button>
	)
}

export const SupportButton: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				'rounded-full bg-primary-light h-16 w-16 p-3 z-50 fixed inset-y-1/2 right-10 max-lg:hidden flex items-center gap-x-1',
				className,
				{
					'pointer-events-none cursor-not-allowed': props.disabled
				}
			)}>
			{children}
		</button>
	)
}
