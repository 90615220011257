import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { useRef, useState } from 'react'

import { MapPinIcon } from 'assets/icons'
import { ReactComponent as MapLocationIcon } from 'assets/svgs/map-location.svg'

const mapContainerStyle = {
	width: '100%',
	height: '100%',
	borderRadius: '0px'
}

const defaultCenter = {
	lat: 31.3945,
	lng: 74.261
}

interface MapProps {
	searchResults: SearchResult[]
	currentLocation: { lat: number; lng: number } | null
	centerPosition?: { lat: number; lng: number }
	height?: string
}

export const Map = ({
	searchResults,
	height = '85vh',
	centerPosition,
	currentLocation
}: MapProps) => {
	const mapRef = useRef<google.maps.Map | null>(null)

	const [map, setMap] = useState<google.maps.Map>()
	const [selectedMarker, setSelectedMarker] = useState<{
		id: number
		lat: number
		lng: number
	} | null>(null)

	const markersData = searchResults
		.filter(result => result.latitude !== null && result.longitude !== null)
		.map((result, index) => ({
			id: index + 1,
			lat: result.latitude as number,
			lng: result.longitude as number
		}))

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
	})

	const mapOptions = {
		disableDefaultUI: true,
		tilt: 0,
		zoomControl: false,
		styles: [
			{
				elementType: 'geometry',
				stylers: [{ color: '#f6f6f6' }]
			},
			{
				elementType: 'labels.icon',
				stylers: [{ visibility: 'on' }]
			},
			{
				elementType: 'labels.text.fill',
				stylers: [{ color: '#523735' }]
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#f5f5f5' }]
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#dcd2be' }]
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#ae9e90' }]
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }]
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry.fill',
				stylers: [{ color: '#c4f0d6' }]
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#447530' }]
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#dfe4ea' }]
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [{ color: '#9fb2cd' }]
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#9fb2cd' }]
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9fb2cd' }]
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9fb2cd' }]
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }]
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }]
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [{ color: '#93daed' }]
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#93daed' }]
			}
		]
	}

	const handleCurrentLocation = () => {
		if (currentLocation) {
			map?.panTo(currentLocation)
			map?.setZoom(16)
		} else {
			alert('Geolocation is not supported by this browser.')
		}
	}

	const handleZoomIn = () => {
		if (map) {
			const newZoom = (map.getZoom() as number) + 1
			map.setZoom(newZoom)
		}
	}

	const handleZoomOut = () => {
		if (map) {
			const newZoom = (map.getZoom() as number) - 1
			map.setZoom(newZoom)
		}
	}

	if (!isLoaded) {
		return null
	}

	return (
		<div className="relative">
			<div className="absolute z-10 right-[26px] bottom-32 flex flex-col gap-4">
				<button
					style={{ boxShadow: '0px 4px 24px 0px #7F9AB233' }}
					onClick={handleCurrentLocation}
					className=" bg-white rounded-xl p-2.5">
					<MapLocationIcon />
				</button>
				<div
					style={{ boxShadow: '0px 4px 24px 0px #7F9AB233' }}
					className="flex rounded-xl flex-col p-2.5 gap-2.5 bg-white">
					<button onClick={handleZoomIn}>
						<PlusIcon className="size-6 text-primary" />
					</button>
					<div className="w-full h-px bg-[#CFD8DC]" />
					<button onClick={handleZoomOut}>
						<MinusIcon className="size-6 text-primary" />
					</button>
				</div>
			</div>
			<GoogleMap
				mapContainerStyle={{ ...mapContainerStyle, height: height }}
				center={
					centerPosition
						? centerPosition
						: markersData.length > 0
							? { lat: markersData[0].lat, lng: markersData[0].lng }
							: defaultCenter
				}
				zoom={12}
				options={mapOptions}
				onLoad={map => {
					setMap(map)
					mapRef.current = map
					map.setZoom(12)
				}}>
				{currentLocation && (
					<MarkerF
						position={currentLocation}
						icon={{
							path: google.maps.SymbolPath.CIRCLE,
							fillColor: 'red',
							fillOpacity: 0.8,
							scale: 8,
							strokeColor: 'white',
							strokeWeight: 2
						}}
					/>
				)}
				{markersData.map(marker => (
					<MarkerF
						key={marker.id}
						position={{ lat: marker.lat, lng: marker.lng }}
						onClick={() => setSelectedMarker(marker)}
						label={{
							text: marker.id.toString(),
							color: 'white',
							fontSize: '0px',
							fontWeight: 'bold'
						}}
						icon={{
							url: MapPinIcon,
							scaledSize: new google.maps.Size(25, 30)
						}}
					/>
				))}
			</GoogleMap>
		</div>
	)
}
