import { MagnifyingGlassIcon, MapPinIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import clsx from 'clsx'

import { ReactComponent as CompanyIcon } from 'assets/svgs/company.svg'
import { ReactComponent as IndustryIcon } from 'assets/svgs/industry.svg'
import { ReactComponent as ServiceIcon } from 'assets/svgs/service.svg'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'

import 'styles/search.css'
import { apiHost } from 'utils/host'

interface Location {
	latitude: number
	longitude: number
}

export const SearchBox = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const tKey = getTKey('home')

	const [country, setCountry] = useState('')
	const [location, setLocation] = useState<Location | null>(null)
	const [isCurrentLocationSelected, setIsCurrentLocationSelected] = useState(false)
	const [name, setName] = useState('')
	const [isFocus, setIsFocus] = useState(false)
	const [suggestions, setSuggestions] = useState({
		companies: [],
		companyTypes: [],
		services: []
	})

	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		options: {
			types: ['(regions)'],
			componentRestrictions: { country: 'ch' }
		},
		onPlaceSelected: place => {
			setIsCurrentLocationSelected(false)
			setCountry(place?.formatted_address ?? '')
		}
	})

	useEffect(() => {
		if ('geolocation' in navigator || isCurrentLocationSelected) {
			navigator.geolocation.getCurrentPosition(
				position => {
					setLocation({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude
					})
				},
				error => {
					console.error('Error fetching location:', error)
				}
			)
		} else {
			console.error('Geolocation is not available in this browser.')
		}
	}, [isCurrentLocationSelected])

	useEffect(() => {
		const fetchCity = async () => {
			if (location) {
				try {
					const { latitude, longitude } = location
					const response = await axios.get(
						apiHost + `/current-location?lat=${latitude}&lng=${longitude}`
					)
					if (response.data.status === 'OK') {
						const addressComponents = response.data.results[0].address_components
						const cityComponent = addressComponents.find((component: any) =>
							component.types.includes('locality')
						)
						const cityName = cityComponent ? cityComponent.long_name : 'City not found'
						setCountry(cityName)
					} else {
						console.error('Error fetching city data:', response.data.status)
					}
				} catch (error) {
					console.error('Error fetching city name:', error)
				}
			}
		}

		fetchCity()
	}, [location])

	useEffect(() => {
		companyService.getSearchSuggestions(name).then(res =>
			setSuggestions({
				companies: res.companies?.companies,
				companyTypes: res.company_types?.company_types,
				services: res.services?.services
			})
		)
	}, [name])

	const showSuggestions = Object.values(suggestions).some(s => s?.length > 0)

	return (
		<div
			className="w-full relative z-20 max-w-md mr-auto p-2 rounded-2xl"
			style={{ backgroundImage: 'linear-gradient(to right, #ffbcc6, #d1c4ff)' }}>
			<div className="w-full bg-white p-6 rounded-lg shadow-lg">
				<div className="space-y-4">
					<div className="relative w-full">
						<div className="relative">
							<MagnifyingGlassIcon className="absolute size-5 left-3 top-1/2 transform -translate-y-1/2 text-primary" />
							<input
								type="text"
								value={name}
								onFocus={() => setIsFocus(true)}
								onBlur={() =>
									setTimeout(() => {
										setIsFocus(false)
									}, 400)
								}
								onChange={event => setName(event.target.value)}
								placeholder={t('search.placeholders.name')}
								className="w-full pl-10 text-primary placeholder:text-primary text-sm py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primary-light bg-[#e9f1f8] focus:ring-0"
							/>
						</div>
						{showSuggestions && isFocus && (
							<SearchSuggestion
								selected={name}
								content={suggestions}
								onSelection={(value: string) => setName(value)}
							/>
						)}
					</div>
					<div className="relative">
						<MapPinIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary size-5" />
						<input
							ref={ref as any}
							value={country}
							autoComplete="new-password"
							onChange={event => setCountry(event.target.value)}
							style={{ boxShadow: '0px 4px 24px 0px #0000001F' }}
							placeholder={t('search.placeholders.location')}
							className="w-full pl-10 py-3 text-primary placeholder:text-primary text-sm border border-gray-300 rounded-lg focus:outline-none focus:border-primary-light bg-[#e9f1f8]"
						/>
					</div>
					<div className="dropdown-menu">
						<div
							onClick={() => setIsCurrentLocationSelected(true)}
							className="flex items-center gap-2 cursor-pointer text-primary hover:text-primary-light px-4 py-2">
							<PaperAirplaneIcon className="h-5 w-5 text-primary" />
							<span className="text-sm font-medium">
								{t('search.placeholders.currentLocation')}
							</span>
						</div>
					</div>
				</div>
				<button
					onClick={() => navigate(`/search-results?name=${name}&country=${country}`)}
					className="w-full bg-[#5964D3] hover:bg-[#7D8AE6] text-white mt-6 py-3 rounded-lg focus:outline-none">
					{t(tKey('buttons.search'))}
				</button>
			</div>
		</div>
	)
}

type SearchContent = {
	companies: { name: string }[]
	companyTypes: { name: string }[]
	services: { name: string }[]
}
interface SearchSuggestionProps {
	content: SearchContent
	selected: string
	onSelection: (value: string) => void
	className?: string
}

export const SearchSuggestion = ({
	content,
	onSelection,
	selected,
	className
}: SearchSuggestionProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('search')

	return (
		<div
			className={clsx(
				'absolute z-20 w-full left-0 rounded-[20px] translate-y-1 grid grid-cols-1 max-h-[280px] overflow-y-auto border border-[#CFD8DC] bg-white text-primary',
				className
			)}>
			{content?.companyTypes.length > 0 && (
				<div className="flex px-6 py-3 border-b border-[#CFD8DC] flex-col gap-y-4 items-start">
					<div className="flex items-center gap-3.5">
						<IndustryIcon />
						<h5 className="font-bold text-lg leading-5 text-primary">
							{t(tKey('labels.industries'))}
						</h5>
					</div>
					<div className="flex w-full flex-col gap-2 items-start">
						{content.companyTypes?.map(type => (
							<div
								key={type.name}
								onClick={() => onSelection(type.name)}
								className={clsx(
									'text-sm cursor-pointer hover:text-primary-light leading-5 text-primary',
									{ '!text-primary-light': selected === type.name }
								)}>
								{type.name}
							</div>
						))}
					</div>
				</div>
			)}

			{content.companies?.length > 0 && (
				<div className="flex px-6 py-3 border-b border-[#CFD8DC] flex-col gap-y-4 items-start">
					<div className="flex items-center gap-3.5">
						<CompanyIcon />
						<h5 className="font-bold text-lg leading-5 text-primary">
							{t(tKey('labels.companies'))}
						</h5>
					</div>
					<div className="flex w-full flex-col gap-2 items-start">
						{content.companies?.map(company => (
							<div
								key={company.name}
								onClick={() => onSelection(company.name)}
								className={clsx(
									'text-sm cursor-pointer hover:text-primary-light leading-5 text-primary',
									{ '!text-primary-light': selected === company.name }
								)}>
								{company.name}
							</div>
						))}
					</div>
				</div>
			)}

			{content.services?.length > 0 && (
				<div className="flex px-6 border-b border-[#CFD8DC] bg-[#B790D833] py-3 flex-col gap-y-4 items-start">
					<div className="flex items-center gap-3.5">
						<ServiceIcon />
						<h5 className="font-bold text-lg leading-5 text-primary">
							{t(tKey('labels.services'))}
						</h5>
					</div>
					<div className="flex w-full flex-col gap-2 items-start">
						{content.services?.map(service => (
							<div
								key={service.name}
								onClick={() => onSelection(service.name)}
								className={clsx(
									'text-sm cursor-pointer hover:text-primary-light leading-5 text-primary',
									{ '!text-primary-light': selected === service.name }
								)}>
								{service.name}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}
